import React, { useEffect, useLayoutEffect, useState, useCallback, useMemo } from 'react'
import { PrimaryButton } from '@fluentui/react/lib/Button'
import { FontIcon as Icon } from '@fluentui/react'

import './styles.css'
import { SmartCapexTool, Kyivstar } from '../../components/common/icons'

export const ORIGIN = process.env.REACT_APP_API || window.location.origin

// eslint-disable-next-line max-len
const COPYRIGHT = '© 1997–2025 Kyivstar. All rights reserved. Use of materials from this site is only allowed with prior written consent of Kyivstar.'

const object2URLEncoded = (element, key = null, list = []) => {
  if (typeof element === 'object') {
    for (const idx of Object.keys(element)) {
      object2URLEncoded(element[idx], key ? `${key}[${idx}]` : idx, list)
    }
  } else {
    list.push(`${key}=${encodeURIComponent(element)}`)
  }
  return list.join('&')
}

const LoginForm = () => {
  const [ username, setUsername ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ invalid, setInvalid ] = useState(false)
  const [ error, setError ] = useState(false)

  useLayoutEffect(() => {
    const element = document.getElementById('back')
    if (element) {
      element.style.backgroundImage = 'url(/conf/background.png)'
    }
  }, [])

  useEffect(() => {
    const error = new URLSearchParams(window.location.search).get('error')
    if (error === 'BadCredentialsException') {
      setInvalid(true)
    } else if (error) {
      setError(true)
    }
  }, [ setInvalid ])

  const doLogin = useCallback(async () => {
    setInvalid(false)
    setError(false)
    try {
      if (ORIGIN === window.location.origin) {
        const form = document.getElementById('login-form')
        form.submit()
      } else {
        const options = {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: object2URLEncoded({ username, password }),
        }
        const res = await fetch(`${ORIGIN}/api/auth/bh2getcookie`, options)
        const { ok, status } = res
        if (ok && status === 204) {
          window.location = '/'
        } else if (status === 403) {
          setInvalid(true)
        } else {
          setError(true)
        }
      }
    } catch (err) {
      console.error(err)
    }
  }, [ username, password, setInvalid, setError ])

  const usernameChange = useCallback((e) => {
    setUsername(e.target.value)
  }, [ setUsername ])

  const passwordChange = useCallback((e) => {
    setPassword(e.target.value)
  }, [ setPassword ])

  const errorMessage = useMemo(() => {
    if (invalid) {
      return 'Incorrect username or password. Type correct credentials and try again'
    }
    if (error) {
      return 'Error connecting to server'
    }
    return null
  }
  , [ invalid, error ])

  return (
    <div id="back" className="background">
      <div className="centered">
        <div className='container'>
        <div className='images'>
          <img src={Kyivstar} className='form-image' title={'Kyivstar'} alt="Kyivstar" />
          <img src={SmartCapexTool} className='form-image' title={'Smart Capex'} alt="Smart Capex" />
        </div>
        <div className="form">
          <div className="form-item">
            <h1>Log In</h1>
          </div>
          {errorMessage && <div className="form-item">
            <div className='error-message'>
              <Icon
                iconName="Error"
                style={{ color: 'red ' }}
                title="Error"
              />
              <span>{errorMessage}</span>
            </div>
          </div>}
          <form id="login-form" action={`${ORIGIN}/api/auth/login/ldap`} method="POST">
            <div className="form-item">
              <label>Enter username</label>
              <input
                className="styled-input"
                name="username"
                placeholder='Username'
                value={username}
                onChange={usernameChange}
              />
            </div>
            <div className="form-item">
              <label>Enter password</label>
              <input
                className="styled-input"
                name="password"
                placeholder='Password'
                type="password"
                value={password}
                onChange={passwordChange}
              />
            </div>
          </form>
          <PrimaryButton
            className='login-button'
            text="Sign in"
            onClick={doLogin}
          />
        </div>
        </div>
      </div>
      <footer>
        <div className='footer-content'>
          <p className='copyright'>{COPYRIGHT}</p>
        </div>
      </footer>
    </div>
  )
}

export default LoginForm
