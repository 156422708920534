import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { initializeIcons } from '@fluentui/react/lib/Icons'

import 'leaflet'
import '@kvforksorg/leaflet.polylinemeasure/Leaflet.PolylineMeasure.js'
import '@kvforksorg/leaflet.polylinemeasure/Leaflet.PolylineMeasure.css'
import '@geoman-io/leaflet-geoman-free'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'

import App from './App'
import { store } from './features/store'

import * as serviceWorker from './serviceWorker'

import './index.css'

initializeIcons('https://res.cdn.office.net/files/fabric-cdn-prod_20240129.001/assets/icons/')

const root = createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
