import React, { useCallback, useEffect, useMemo } from 'react'
import area from '@turf/area'
import { useHistory } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Breadcrumb, Dropdown, Icon, Label, Spinner, SpinnerSize, TooltipHost } from '@fluentui/react'

import { EDIT_PROJECTS } from '../constants/access'
import { DATA_TYPES } from '../constants/common'
import {
  activeProjectId, activeProjectName, ensureUserProject, selectIsDefaultProject,
} from '../features/projects/projectsSlice'
import { selectStages } from '../features/loading/loadingSlice'
import { selectUserAccess } from '../features/login/loginSlice'
import { selectVectorItems } from '../features/vector/vectorSlice'
import { setPanel } from '../features/panel/panelSlice'
import { saveAllZones, selectComputationZone, selectFilteringZone } from '../features/geo/geoSlice'
import { selectBCFields, selectGCNames, setBCList, clearNames } from '../features/bc/bcSlice'
import {
  selectCountBaseStations, selectCountBaseStationsFull, selectCountSectors, selectCountSectorsFull, selectCountSites,
  selectCountSitesFull, selectSectorsFilters, selectSitesFilters, selectBaseStationsFilters, selectCountComplaintsFull,
  selectCountComplaints, selectComplaintsFilters, resetAllFilters,
} from '../features/network/networkSlice'
import { loadTRFData, selectUpdatedValues, setUpdatedValues } from '../features/trf/trfSlice'
import {
  selectSettingsDeepFiltrationUp, selectSettingsDeepFiltrationDown,
} from '../features/settings/settingsSlice'
import { crumbsByPath, KEYS, ROUTES } from '../constants/routes'
import { DefaultButton } from '../components/common/Button'
import Badge from '../components/common/Badge'
import { LOCALE, empty } from '../utils/format'

import './Caption.css'
import { toPolygon } from '../components/Map/utils'
import { useConfirm } from '../components/common/Confirm'
import { RESET_ALL_FILTERS } from '../constants/features'
import TopNavMenu from '../components/TopNavMenu'
import { TOP_NAV_MENU } from '../constants/menus'
import useWithSectorCoverage from '../hooks/useWithSectorCoverage'

const PROJECT_NAME_SHOW_LIMIT = 30
const VECTOR_MAP_NAME_SHOW_LIMIT = 15

const cabinetLabel = ROUTES[KEYS.CABINET].label

const checkMarkText = '✔ '

export const RESET_FILTERS_CONFIRMATION_MESSAGE = [
  'Filters in Sites, RBS and Sectors tables, Filtering zone and Computation zone will be deleted.',
  'Additionally, deep filtration will also be disabled.',
  'Do you want to continue?',
]

const renderItemText = ({ text, contentTooltip }) => {
  return (
    <TooltipHost content={contentTooltip}>
      {text}
    </TooltipHost>
  )
}

const Caption = ({ routes }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { ask, renderConfirm } = useConfirm()

  const { section, objectId } = useMemo(() => crumbsByPath(routes?.location?.pathname), [ routes ])
  const isCabinet = useMemo(() => section === KEYS._CABINET, [ section ])

  const projectId = useSelector(activeProjectId)
  const projectName = useSelector(activeProjectName)
  const isDefaultProject = useSelector(selectIsDefaultProject)
  const userAccess = useSelector(selectUserAccess, shallowEqual)
  const vectorMaps = useSelector(selectVectorItems)
  const bcFields = useSelector(selectBCFields)
  const groupCaseNames = useSelector(selectGCNames)
  const stages = useSelector(selectStages)

  const filteringZone = useSelector(selectFilteringZone)
  const computationZone = useSelector(selectComputationZone)
  const sitesCountFull = useSelector(selectCountSitesFull)
  const sitesCountList = useSelector(selectCountSites)
  const sitesFilters = useSelector(selectSitesFilters)
  const sectorCountFull = useSelector(selectCountSectorsFull)
  const sectorCountList = useSelector(selectCountSectors)
  const sectorsFilters = useSelector(selectSectorsFilters)
  const selectRBSCountFull = useSelector(selectCountBaseStationsFull)
  const selectRBSCountList = useSelector(selectCountBaseStations)
  const rbsFilters = useSelector(selectBaseStationsFilters)
  const complaintsCountFull = useSelector(selectCountComplaintsFull)
  const complaintsCountList = useSelector(selectCountComplaints)
  const complaintsFilters = useSelector(selectComplaintsFilters)
  const deepFiltrationUp = useSelector(selectSettingsDeepFiltrationUp)
  const deepFiltrationDown = useSelector(selectSettingsDeepFiltrationDown)

  const updatedTRF = useSelector(selectUpdatedValues)

  const { isLoadingSectorsCoverage, isShowSectorsCoverage } = useWithSectorCoverage()

  const onCreate = useCallback(() => {
    const doOnCreate = async () => {
      const id = await dispatch(ensureUserProject({ isManualCreate: true }))
      if (id !== '_') {
        // Потрібно очистити в редаксі список бізнес-кейсів,
        // оскільки в проєкті користувача ми не показуємо бізнес-кейси дефолтного проєкту.
        dispatch(setBCList({ dataType: DATA_TYPES.BUSINESS_CASES, list: [], fields: bcFields }))
        dispatch(clearNames())
        await dispatch(setPanel(null))
        await dispatch(saveAllZones())
        history.push(`/${id}/map`)
      }
    }

    if (updatedTRF) {
      const action = async () => {
        const path = window.location.pathname
        const pathArray = path.split('/')
        const view = pathArray.pop()
        const module = pathArray.pop()
        if (module === 'trf') {
          await dispatch(setUpdatedValues(null))
          await dispatch(loadTRFData(view))
        }

        doOnCreate()
      }
      ask(
        action,
        empty,
        null,
        {
          title: 'Confirmation',
          messages: [
            'There are unsaved changes!',
            'Do you want to continue?',
          ],
          textYesBtn: 'Yes',
          textNoBtn: 'No',
        },
      )
    } else {
      doOnCreate()
    }
  }, [ dispatch, history, bcFields, updatedTRF, ask ])

  const onResetAllFilters = useCallback(() => {
    const filterNetworkRequired = sitesCountFull !== sitesCountList ||
      sectorCountFull !== sectorCountList ||
      selectRBSCountFull !== selectRBSCountList ||
      complaintsCountFull !== complaintsCountList
    const doResetFilters = () => dispatch(resetAllFilters({ filterNetworkRequired }))
    ask(
      doResetFilters,
      empty,
      null,
      {
        title: 'Confirmation',
        messages: RESET_FILTERS_CONFIRMATION_MESSAGE,
        textYesBtn: 'Ok',
        textNoBtn: 'Cancel',
      },
    )
  }, [ ask, dispatch, sitesCountFull, sitesCountList, sectorCountFull, sectorCountList,
    selectRBSCountFull, selectRBSCountList, complaintsCountFull, complaintsCountList ])

  useEffect(() => {
    let title = 'Smart Capex'
    if (isCabinet) {
      title = `${cabinetLabel} – ${title}`
    } else if (projectName) {
      title = `${projectName} – ${title}`
    }
    document.title = title
  }, [ projectName, isCabinet ])

  const projectLabel = useMemo(() => projectName || 'Default project', [ projectName ])

  const items = useMemo(() => {
    if (isCabinet) {
      return [
        {
          key: 'section',
          text: cabinetLabel,
        },
      ]
    } else {
      let text = projectLabel
      if (text && text.length > PROJECT_NAME_SHOW_LIMIT + 3) {
        text = `${text.slice(0, PROJECT_NAME_SHOW_LIMIT)}...`
      }
      const crumbs = []
      if (!isDefaultProject) {
        crumbs.push({
          key: 'project',
          text,
          contentTooltip: projectLabel,
          onRenderContent: projectLabel.length > PROJECT_NAME_SHOW_LIMIT + 3 ? renderItemText : undefined,
        })
      }
      crumbs.push({
        key: 'section',
        text: ROUTES[section]?.label,
      })

      if (section === KEYS.VECTOR_MAPS && objectId && Array.isArray(vectorMaps)) {
        const vectorMap = vectorMaps.find((item) => (item.id === objectId))
        const contentTooltip = vectorMap?.name ?? ''
        const isTooltip = contentTooltip.length > VECTOR_MAP_NAME_SHOW_LIMIT + 3
        const text = isTooltip ? `${contentTooltip.slice(0, VECTOR_MAP_NAME_SHOW_LIMIT)}...` : contentTooltip
        if (vectorMap) {
          crumbs.push({
            key: objectId,
            text,
            contentTooltip,
            onRenderContent: isTooltip ? renderItemText : undefined,
          })
        }
      }
      if (section === KEYS.BUSINESS_CASES) {
        if (objectId) {
          const groupCase = groupCaseNames && groupCaseNames.find(({ id }) => objectId === id)
          crumbs.push({
            key: 'createCase',
            text: 'Create Case',
          })
          if (groupCase) {
            crumbs.push({
              key: 'groupCase',
              text: groupCase.name,
            })
          }
        }
      }
      if (section === KEYS.TRF) {
        crumbs.push({
          key: 'details',
          text: 'Period',
          onRenderContent: () => (
            <Dropdown
              className={'capex-dropdown-menu'}
              selectedKey={objectId}
              options={[
                {
                  key: 'monthly',
                  text: 'Monthly',
                },
                {
                  key: 'yearly',
                  text: 'Yearly',
                },
              ]}
              onChange={async (_, item) => {
                history.push(`/${projectId || '_'}/trf/${item.key}`)
              }}
            />
          ),
        })
      }
      return crumbs
    }
  }, [
    isCabinet, projectLabel, section, vectorMaps, objectId, history, groupCaseNames,
    isDefaultProject, projectId,
  ])

  const filterApplied = useMemo(() => {
    if (stages) { // Loading a project, do not show filter
      return ''
    }
    const result = []
    if (sitesCountList < sitesCountFull) {
      result.push(`Selected ${sitesCountList.toLocaleString(LOCALE)} out of ${sitesCountFull.toLocaleString(LOCALE)} Sites`)
      if (sitesFilters?.comparisonRules?.length > 0) {
        const count = sitesFilters.comparisonRules.reduce((res, group) => {
          return res + group.length
        }, 0)
        result.push(`  ${checkMarkText}${count} filter(s) applied`)
      }
    }

    if (selectRBSCountList < selectRBSCountFull) {
      result.push(`Selected ${selectRBSCountList.toLocaleString(LOCALE)} out of ${selectRBSCountFull.toLocaleString(LOCALE)} RBS`)
      if (rbsFilters?.comparisonRules?.length > 0) {
        const count = rbsFilters.comparisonRules.reduce((res, group) => {
          return res + group.length
        }, 0)
        result.push(`  ${checkMarkText}${count} filter(s) applied`)
      }
    }

    if (sectorCountList < sectorCountFull) {
      result.push(`Selected ${sectorCountList.toLocaleString(LOCALE)} out of ${sectorCountFull.toLocaleString(LOCALE)} Sectors`)
      if (sectorsFilters?.comparisonRules?.length > 0) {
        const count = sectorsFilters.comparisonRules.reduce((res, group) => {
          return res + group.length
        }, 0)
        result.push(`  ${checkMarkText}${count} filter(s) applied`)
      }
    }

    if (complaintsCountList < complaintsCountFull) {
      result.push(`Selected ${complaintsCountList.toLocaleString(LOCALE)} out of ${complaintsCountFull.toLocaleString(LOCALE)} Complaints`)
      if (complaintsFilters?.comparisonRules?.length > 0) {
        const count = complaintsFilters.comparisonRules.reduce((res, group) => {
          return res + group.length
        }, 0)
        result.push(`  ${checkMarkText}${count} filter(s) applied`)
      }
    }

    if (result.length > 0) {
      if (filteringZone) {
        const zoneArea = area(toPolygon(filteringZone, 3))
        if (zoneArea > 0) {
          const surface = Math.round(zoneArea / 1e3) / 1e3
          result.push(`${checkMarkText}Filtering zone applied: ${surface.toLocaleString(LOCALE)} km²`)
        } else {
          result.push(`${checkMarkText}Filtering zone applied`)
        }
      }
    }
    if (computationZone) {
      const zoneArea = area(toPolygon(computationZone, 3))
      if (zoneArea > 0) {
        const surface = Math.round(zoneArea / 1e3) / 1e3
        result.push(`${checkMarkText}Computation zone applied: ${surface.toLocaleString(LOCALE)} km²`)
      } else {
        result.push(`${checkMarkText}Computation zone applied`)
      }
    }
    if (result.length > 0) {
      if (deepFiltrationUp || deepFiltrationDown) {
        result.push('Direction of deep filtration')
        if (deepFiltrationUp) {
          result.push(`  ${checkMarkText}Up`)
        }
        if (deepFiltrationDown) {
          result.push(`  ${checkMarkText}Down`)
        }
      }
    }

    return result
  }, [
    filteringZone, computationZone, sitesCountFull, sitesCountList, sectorCountFull, sectorCountList,
    selectRBSCountFull, selectRBSCountList, sitesFilters, sectorsFilters, rbsFilters, complaintsCountFull,
    complaintsCountList, complaintsFilters, deepFiltrationUp, deepFiltrationDown, stages,
  ])

  const filterAppliedHTML = useMemo(() => {
    return RESET_ALL_FILTERS && filterApplied && (
      <div>
        {filterApplied.map((l, ind) => {
          const iconWithMargin = l.startsWith(`  ${checkMarkText}`)
          const withIcon = l.startsWith(checkMarkText)
          return (
            <p key={ind}>
              {(withIcon || iconWithMargin) &&
                <Icon
                  iconName="CheckMark"
                  style={{
                    color: 'green',
                    alignSelf: 'center',
                    display: 'inline',
                    marginRight: '3px',
                    marginLeft: iconWithMargin ? '5px' : 0,
                  }}
                />}
              <Label style={{ display: 'inline' }}>{l.replace(checkMarkText, '').trim()}</Label>
            </p>
          )
        })}
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <DefaultButton onClick={onResetAllFilters} text="Reset Filters" />
        </div>
      </div>
    )
  }, [ filterApplied, onResetAllFilters ])

  const CoverageMenu = ({ spinnerClassName }) => (
    <div className={'coverage-menu'}>
      {isLoadingSectorsCoverage
        ? <Spinner size={SpinnerSize.medium} className={spinnerClassName} />
        : isShowSectorsCoverage && <TopNavMenu
            items={TOP_NAV_MENU[KEYS.MAP_COVERAGE]}
          />}
    </div>
  )

  const CAPTION = !isCabinet && isDefaultProject && userAccess[EDIT_PROJECTS]
    ? (section === KEYS.TRF
        ? (
          <div className='breadcrumbContainer'>
            <DefaultButton onClick={onCreate} text="Create Project" />
            <Breadcrumb items={items} onReduceData={empty} />
            <CoverageMenu />
          </div>
          )
        : (
          <div className='breadcrumbContainer'>
            <DefaultButton onClick={onCreate} text="Create Project" />
            <CoverageMenu spinnerClassName={'coverage-spinner'} />
          </div>
          )
      )
    : (
        <div className='breadcrumbContainer'>
          <Breadcrumb items={items} onReduceData={empty} />
          <CoverageMenu />
        </div>
      )

  return <>
    {isCabinet || !filterApplied || filterApplied?.length === 0
      ? CAPTION
      : (
        <Badge
          value={''}
          color={getComputedStyle(document.documentElement).getPropertyValue('--active-additional')}
          title={!RESET_ALL_FILTERS && filterApplied.join('\n')}
          htmlTitle={RESET_ALL_FILTERS && filterAppliedHTML}
        >
          {CAPTION}
        </Badge>
        )}
      {renderConfirm()}
    </>
}

export default Caption
