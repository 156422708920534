import { Pane, Polygon } from 'react-leaflet'
import React, { useCallback, useEffect, useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { reverseLngLat } from '../../utils'
import { SECTOR_BASE, SECTOR_COLORS } from '../../../../constants/network'
import { determineColor, isItemVisible } from '../../render'
import { DEFAULT_COLORS } from '../../draw'
import {
  selectNetworkLayersVisibility, selectSectorFldIdx, selectSectors,
} from '../../../../features/network/networkSlice'
import { selectSettingsDisplay } from '../../../../features/settings/settingsSlice'

const TargetCoverages = ({
  map, targetCoverages, activeSector, colorAttributeIdx, version,
}) => {
  const sectors = useSelector(selectSectors)
  const settingsDisplay = useSelector(selectSettingsDisplay)
  const [ idIdx2,, lngIdx2, latIdx2,,,, tIdx2, statusIdx2 ] = useSelector(selectSectorFldIdx, shallowEqual)
  const networkLayersVisibility = useSelector(selectNetworkLayersVisibility, shallowEqual)
  const {
    sectorsVisibility,
    sectorTypesVisibility,
    sectorTypeIdx,
    sectorSortFn,
  } = (networkLayersVisibility || {})

  const sectorColorById = useCallback((sectorId) => {
    const sectorIdx = sectors.findIndexById(sectorId)
    if (sectorIdx >= 0) {
      const item = sectors.getList()[sectorIdx]
      const base = SECTOR_BASE[item[tIdx2]]
      return determineColor(item, settingsDisplay[base], colorAttributeIdx[base], idIdx2) ||
        SECTOR_COLORS[item[tIdx2]] || DEFAULT_COLORS.SECTOR
    } else {
      return null
    }
  }, [ sectors, tIdx2, settingsDisplay, colorAttributeIdx, idIdx2 ])

  const types = useMemo(() => sectorTypesVisibility?.getList(), [ sectorTypesVisibility ])

  const isSectorVisible = useCallback((sectorId) => {
    if (!sectors || !sectorsVisibility || !types) {
      return false
    }
    const sectorIdx = sectors.findIndexById(sectorId)
    if (sectorIdx >= 0) {
      const item = sectors.getList()[sectorIdx]
      return isItemVisible(item, sectorTypeIdx, types, sectorSortFn, idIdx2, latIdx2, lngIdx2, statusIdx2)
    } else {
      return false
    }
  }, [ sectors, sectorsVisibility, types, sectorTypeIdx, sectorSortFn, idIdx2, latIdx2, lngIdx2, statusIdx2 ])

  const layers = useMemo(() => Object.entries(targetCoverages ?? {})
    .filter(([ sectorId, { geometry } ]) => geometry.length && isSectorVisible(sectorId))
    .map(([ sectorId, { geometry, properties, hash } ]) => {
      const colorById = sectorColorById(sectorId)
      return colorById
        ? (
            <Polygon
              id={sectorId}
              targetId={`targetCoverage-${sectorId}`}
              key={`targetCoverage-${hash}-${sectorId}${activeSector === sectorId ? '-active' : ''}-${version}`}
              positions={reverseLngLat(geometry)}
              pmIgnore={sectorId !== activeSector}
              stroke={sectorId === activeSector}
              color={'red'}
              fillColor={colorById}
              fillOpacity={0.5}
              description={properties?.description}
            />
          )
        : null
    })
    .filter(Boolean), [ targetCoverages, activeSector, isSectorVisible, sectorColorById, version ])

  useEffect(() => {
    setTimeout(() => {
      map._targetCoverageLayers = []
      map._activeTargetCoverageLayer = null
      map.eachLayer((layer) => {
        if (layer.options.targetId && layer.options.targetId.startsWith('targetCoverage-')) {
          map._targetCoverageLayers.push(layer)
          if (layer.options.id === activeSector) {
            map._activeTargetCoverageLayer = layer
          }
        }
      })
    })
    return () => {
      map._targetCoverageLayers = []
      map._activeTargetCoverageLayer = null
    }
  }, [ map, layers, activeSector ])

  return (
    <Pane name="targetCoverage" style={{ zIndex: 203, pointerEvents: 'none' }}>
      {layers}
    </Pane>
  )
}

export default TargetCoverages
