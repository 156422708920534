import React, { useCallback, useEffect, useState } from 'react'
import { Dialog, DialogType, DialogFooter, ContextualMenu } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { PrimaryButton, DefaultButton } from './Button'

const modalProps = {
  isBlocking: false,
  styles: {
    main: {
      maxWidth: 1600,
    },
  },
  dragOptions: {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
    keepInBounds: true,
  },
}

const DialogWithApply = ({
  title = 'Dialog',
  subText,
  data,
  content,
  onSaveData,
  onClose,
  hidden,
  type = DialogType.normal,
  styles,
  additionalButtons,
  contentRef,
  readOnly,
}) => {
  const Content = content

  const [ modified, { setTrue: setModifiedTrue, setFalse: setModifiedFalse } ] = useBoolean(false)
  const [ valid, setValid ] = useState(true)
  const [ currentData, setCurrentData ] = useState(data)

  useEffect(() => {
    setCurrentData(data)
  }, [ setCurrentData, data ])

  const onChange = useCallback((data) => {
    if (JSON.stringify(currentData) !== JSON.stringify(data)) {
      setCurrentData(data)
      setModifiedTrue()
    }
  }, [ setCurrentData, setModifiedTrue, currentData ])

  const onApply = useCallback(() => {
    onSaveData(currentData)
    setModifiedFalse()
  }, [ onSaveData, currentData, setModifiedFalse ])

  const onOK = useCallback(() => {
    if (!readOnly && modified) {
      onApply()
    }
    onClose()
  }, [ onApply, onClose, modified, readOnly ])

  const onCancel = useCallback(() => {
    setCurrentData(data)
    setModifiedFalse()
    onClose()
  }, [ setModifiedFalse, onClose, setCurrentData, data ])

  return (
    <Dialog
      hidden={hidden}
      onDismiss={onClose}
      dialogContentProps={{
        type: type,
        title,
        subText,
      }}
      modalProps={modalProps}
      styles={styles}
    >
      <Content
        data={currentData}
        modified={modified}
        onChange={onChange}
        onValid={setValid}
        readOnly={readOnly}
        ref={contentRef}
      />
      <DialogFooter>
        <PrimaryButton
          onClick={onOK}
          text={!readOnly && modified ? 'OK' : 'Close'}
          disabled={modified && !valid}
          style={{ minWidth: 104 }}
        />
        <DefaultButton
          onClick={onCancel}
          text="Cancel"
          disabled={readOnly || !modified}
          style={{ minWidth: 104 }}
        />
        <DefaultButton
          onClick={onApply}
          text="Apply"
          disabled={readOnly || !modified || !valid}
          style={{ minWidth: 104 }}
        />
        {Array.isArray(additionalButtons) && additionalButtons.map((button, index) => (
          <DefaultButton
            key={index}
            {...button}
          />
        ))}
      </DialogFooter>
    </Dialog>
  )
}

export default DialogWithApply
