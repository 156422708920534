import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../api'
import { activeProjectId } from '../projects/projectsSlice'
import { findIndex } from '../network/indexing'
import { SECTOR_NAME_FIELD, SECTOR_SITE_FIELD, SECTOR_STATUS_FIELD, STATUS_ACTIVE } from '../../constants/network'
import {
  convertCoverage, elementsRedraw, filterBaseStationsUp, filterSitesUp,
  resetAllFilters, resetAllZones,
  selectSectorFields, selectSectorsFull,
  setSectorList,
} from '../network/networkSlice'
import { DATA_TYPES } from '../../constants/common'
import { addFilterToTable, resetFilterInTable } from '../filters/filters'
import { saveSettings } from '../settings/settingsSlice'

const initialState = {
  isLoading: false,
  coverage: null,
  siteIds: null,
  updateFilters: false,
  errorMessages: null,
}

const sitesTargetCoverage = createAsyncThunk(
  'network/getSitesTargetCoverage',
  api.network.getSitesTargetCoverage,
)

export const fillSectorsCoverages = createAsyncThunk(
  'network/fillSectorsCoverages',
  api.network.fillSectorsCoverages,
)

export const deleteAllCoverages = createAsyncThunk(
  'network/deleteAllCoverages',
  api.network.deleteAllCoverages,
)

const withSectorsCoverageSlice = createSlice({
  name: 'withSectorsCoverage',
  initialState,
  reducers: {
    setIsLoadingCoverage: (state, action) => {
      state.isLoading = action.payload
    },
    setCoverage: (state, action) => {
      state.coverage = action.payload
    },
    setSiteIds: (state, action) => {
      state.siteIds = action.payload
    },
    setUpdateFilters: (state, action) => {
      state.updateFilters = action.payload
    },
    setErrorMessages: (state, action) => {
      state.errorMessages = action.payload
    },
  },
})

const {
  setIsLoadingCoverage, setCoverage, setSiteIds, setUpdateFilters,
} = withSectorsCoverageSlice.actions

export const {
  setErrorMessages,
} = withSectorsCoverageSlice.actions

export const selectIsLoadingCoverage = (state) => state.withSectorsCoverage.isLoading
export const selectCoverage = (state) => state.withSectorsCoverage.coverage
export const selectCoverageSiteIds = (state) => state.withSectorsCoverage.siteIds
export const selectUpdateFilters = (state) => state.withSectorsCoverage.updateFilters
export const selectErrorMessages = (state) => state.withSectorsCoverage.errorMessages

export const getSitesTargetCoverage = (siteIds) => async (dispatch, getState) => {
  const state = getState()
  const projectId = activeProjectId(state)
  const result = await dispatch(sitesTargetCoverage({
    activeProjectId: projectId, siteIds,
  }))
  return convertCoverage(result, state)
}

export const loadSectorsCoverage = ({ sectors, siteId, showOnMap, updateFilters }) => async (dispatch, getState) => {
  try {
    dispatch(setIsLoadingCoverage(true))

    const state = getState()

    const projectId = activeProjectId(state)
    const sectorFull = selectSectorsFull(state)
    const fields = selectSectorFields(state)
    const sectorNameFldIdx = findIndex(fields, SECTOR_NAME_FIELD)
    const sectorSiteFldIdx = findIndex(fields, SECTOR_SITE_FIELD)
    const sectorStatusFldIdx = findIndex(fields, SECTOR_STATUS_FIELD)

    const siteIds = []
    const activeSectors = []
    sectors.forEach((sector) => {
      const sectorRange = sectorFull.findRangeByValue(sector, SECTOR_NAME_FIELD)
      if (sectorRange && sectorRange?.length > 0) {
        const siteId = sectorFull.getList()[sectorRange[0]][sectorSiteFldIdx]
        const status = sectorFull.getList()[sectorRange[0]][sectorStatusFldIdx]
        if (status === STATUS_ACTIVE) {
          activeSectors.push(sector)
          if (siteId && !siteIds.includes(siteId)) {
            siteIds.push(siteId)
          }
        }
      }
    })

    if (activeSectors.length === 0) {
      dispatch(setErrorMessages('No active sectors found'))
      return
    }

    if (siteId) {
      siteIds.push(siteId)
      const sectorIndexes = sectorFull.findRangeByValue(siteId, SECTOR_SITE_FIELD)
      if (sectorIndexes?.length > 0) {
        const list = sectorFull.getList()
        sectorIndexes.forEach((index) => {
          const name = list[index]?.[sectorNameFldIdx]
          if (!activeSectors.includes(name)) {
            activeSectors.push(name)
          }
        })
      }
    }

    if (updateFilters) {
      dispatch(resetAllZones())
      dispatch(resetFilterInTable(DATA_TYPES.SITES))
      dispatch(resetFilterInTable(DATA_TYPES.BASE_STATIONS))
      // Add filters
      const filter = activeSectors.reduce((agg, value) => {
        agg.comparisonRules.push([ {
          columnIndex: sectorNameFldIdx,
          type: 'string',
          comparisonOperator: '=',
          value,
        } ])
        return agg
      }, { inversion: false, comparisonRules: [] })
      const deepFiltration = {
        up: true,
        down: true,
      }
      await dispatch(saveSettings({ deepFiltration }, false, false, false))
      await dispatch(addFilterToTable({ dataType: DATA_TYPES.SECTORS, filter, reset: true, doNotSaveOnserver: true }))

      // Filtering sectors using index
      const filtered = activeSectors.reduce((res, sectorName) => {
        const sectorIdxs = sectorFull.findRangeByValue(sectorName, SECTOR_NAME_FIELD)
        if (sectorIdxs) {
          const sector = sectorFull.getList()[sectorIdxs[0]]
          res.push(sector)
        }
        return res
      }, [])
      await dispatch(setSectorList(filtered))

      await dispatch(filterBaseStationsUp())
      await dispatch(filterSitesUp())

      dispatch(setUpdateFilters(true))

      dispatch(elementsRedraw())
    }

    if (showOnMap) {
      showOnMap()
    }

    await dispatch(fillSectorsCoverages({ activeProjectId: projectId, sectors: activeSectors }))

    const coverage = await dispatch(getSitesTargetCoverage(siteIds))
    dispatch(setCoverage(coverage))
    dispatch(setSiteIds(siteIds))
    dispatch(deleteAllCoverages({ activeProjectId: projectId }))
  } finally {
    dispatch(setIsLoadingCoverage(false))
  }
}

export const removeSectorsCoverage = (removeAllFilters) => async (dispatch, getState) => {
  try {
    dispatch(setIsLoadingCoverage(true))
    dispatch(setCoverage(null))
    if (removeAllFilters) {
      await dispatch(resetAllFilters())
      dispatch(setUpdateFilters(false))
    }
  } finally {
    dispatch(setIsLoadingCoverage(false))
  }
}

export default withSectorsCoverageSlice.reducer
