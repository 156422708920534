import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { mergeStyles, DefaultButton, ActionButton, Spinner, SpinnerSize } from '@fluentui/react'
import { cabinet as t, KEYS } from '../constants'
import Grid from '../../common/Grid'
import { EMPTY } from '../../../constants'
import { COLUMNS, contextMenu, getColumnIndexById, TABLE_ID } from './settings'

const createIcon = {
  iconName: 'EditCreate',
}

const openIcon = {
  iconName: 'Edit',
}

const deleteIcon = {
  iconName: 'Delete',
}
const copyIcon = {
  iconName: 'Copy',
}

const shareProjectIcon = {
  iconName: 'ShareiOS',
}

const changeOwnerIcon = {
  iconName: 'Edit',
}

const iconClass = mergeStyles({
  marginTop: 1,
  marginRight: 8,
})

const Projects = ({
  amountSelected,
  data,
  onDelete,
  onCopy,
  onAfterEdit,
  onShare,
  onUnshare,
  onChangeOwner,
  onCreate,
  onOpen,
  hotGridRef,
  loading,
  setCells,
  nonRemovable,
  changeOwner,
  canOpen,
  withGrants,
  user,
  showContextMenu,
}) => {
  const handlingContextMenu = useCallback((e, data) => {
    switch (e) {
      case 'delete': !nonRemovable && onDelete([ data ])
        break
      case 'copy': onCopy(data)
        break
      case 'share': onShare([ data ])
        break
      case 'open': onOpen(data)
        break
      default:
    }
  }, [ nonRemovable, onDelete, onCopy, onShare, onOpen ])

  const contextMenuMemo = useMemo(() => {
    return contextMenu(handlingContextMenu, user)
  }, [ handlingContextMenu, user ])

  const columns = useMemo(() => {
    return COLUMNS.map((column) => ({ ...column, id: `${TABLE_ID}.${column.id}` }))
  }, [])

  const checkColumnIndex = useMemo(() => {
    return getColumnIndexById(KEYS.CHECK)
  }, [])

  const firstCheckedProject = useCallback(() => {
    const projects = hotGridRef?.current?.props?.data ?? []
    return projects.find((project) => project[checkColumnIndex])
  }, [ hotGridRef, checkColumnIndex ])

  const allCheckedProjects = useCallback(() => {
    const projects = hotGridRef?.current?.props?.data ?? []
    return projects.filter((project) => project[checkColumnIndex])
  }, [ hotGridRef, checkColumnIndex ])

  const handleOpen = useCallback(() => {
    onOpen(firstCheckedProject())
  }, [ firstCheckedProject, onOpen ])

  const handleCopy = useCallback(() => {
    onCopy(firstCheckedProject())
  }, [ firstCheckedProject, onCopy ])

  const handleDelete = useCallback(() => {
    onDelete(allCheckedProjects())
  }, [ allCheckedProjects, onDelete ])

  const handleShare = useCallback(() => {
    onShare(allCheckedProjects())
  }, [ allCheckedProjects, onShare ])

  const handleUnshare = useCallback(() => {
    onUnshare(allCheckedProjects())
  }, [ allCheckedProjects, onUnshare ])

  const handleChangeOwner = useCallback(() => {
    onChangeOwner(allCheckedProjects())
  }, [ allCheckedProjects, onChangeOwner ])

  return (
      <>
        <DefaultButton
          disabled={loading}
          text={t.create}
          iconProps={createIcon}
          onClick={onCreate}
          allowDisabledFocus
        />
        <ActionButton
          disabled={amountSelected !== 1 || !canOpen || loading}
          iconProps={openIcon}
          className={iconClass}
          onClick={handleOpen}
        >
          {t.open}
        </ActionButton>
        <ActionButton
          disabled={!amountSelected || loading}
          iconProps={deleteIcon}
          className={iconClass}
          onClick={handleDelete}
        >
          {t.delete}
        </ActionButton>
        <ActionButton
          disabled={amountSelected !== 1 || loading}
          iconProps={copyIcon}
          className={iconClass}
          onClick={handleCopy}
        >
          {t.makeACopy}
        </ActionButton>
        <ActionButton
          disabled={!amountSelected || loading || nonRemovable}
          iconProps={shareProjectIcon}
          className={iconClass}
          onClick={handleShare}
        >
          {t.share}
        </ActionButton>
        <ActionButton
          disabled={amountSelected !== 1 || loading || nonRemovable || !withGrants}
          iconProps={shareProjectIcon}
          className={iconClass}
          onClick={handleUnshare}
        >
          {t.unshare}
        </ActionButton>
        <ActionButton
          disabled={!amountSelected || loading || !changeOwner}
          iconProps={changeOwnerIcon}
          className={iconClass}
          onClick={handleChangeOwner}
        >
          {t.changeOwner}
        </ActionButton>
        <Grid
          dataType={'Projects'}
          data={data ?? EMPTY.ARRAY}
          refHot={hotGridRef}
          columns={columns}
          manualColumns={false}
          contextMenu={showContextMenu && contextMenuMemo}
          onDblClick={onOpen}
          onAfterEdit={onAfterEdit}
          cells={setCells}
          fillHandle={false}
          isFullViewHeader
        />
        {loading
          ? <Spinner className="centered" size={SpinnerSize.large} />
          : null
        }
    </>
  )
}

Projects.propTypes = {
  amountSelected: PropTypes.number,
  projects: PropTypes.array,
  onDelete: PropTypes.func,
  onCopy: PropTypes.func,
  onChangeCheck: PropTypes.func,
  onShare: PropTypes.func,
  onCreate: PropTypes.func,
  onOpen: PropTypes.func,
  hotTableRef: PropTypes.object,
  setCells: PropTypes.func,
}

export default Projects
