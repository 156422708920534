import convert from 'color-convert'
import { SECTOR_COLORS } from '../../constants/network'
import { DEF_HEIGHT, DEF_WIDTH } from '../Panels/Customization/MapStyle/IconSector'
import { getCssVar } from '../../utils/css'

import './draw.css'

const TEXT_LABELS_UNDER_ZOOM = 10
const ACTIVE_STROKE_COLOR = '#FF0000'
const SELECTED_FILL_COLOR = '#98ff00'
const SELECTED_STROKE_COLOR = '#6cb500'
const STROKE_COLOR = '#000000'
const COMPLAINT_STROKE_COLOR = '#1fa820'
const SEMI_TRANSPARENT = '40'
const DIRECT_FONTS = [ 'italic', 'bold' ]

export const DEFAULT_COLORS = {
  SITE: '#8080FF',
  SECTOR: '#68D868',
  COMPLAINT: '#62f60e',
}

setTimeout(() => {
  DEFAULT_COLORS.SITE = getCssVar('--yellow-additional', DEFAULT_COLORS.SITE)
  DEFAULT_COLORS.SECTOR = getCssVar('--CTA-primary-pressed', DEFAULT_COLORS.SECTOR)
  SECTOR_COLORS.GSM900 = DEFAULT_COLORS.SECTOR
  SECTOR_COLORS.GSM1800 = DEFAULT_COLORS.SECTOR
  SECTOR_COLORS.LTE900 = DEFAULT_COLORS.SECTOR
  SECTOR_COLORS.LTE1800 = DEFAULT_COLORS.SECTOR
  SECTOR_COLORS.LTE2100 = DEFAULT_COLORS.SECTOR
  SECTOR_COLORS.LTE2300 = DEFAULT_COLORS.SECTOR
  SECTOR_COLORS.LTE2600 = DEFAULT_COLORS.SECTOR
  SECTOR_COLORS.UMTS2100 = DEFAULT_COLORS.SECTOR
}, 0)

const prp = (from, to, factor) => from + (to - from) * factor

const calculateColor = (from, to, factor) => {
  if (!factor) {
    return from
  }
  const fa = +`0x${from.slice(7)}`
  const ta = +`0x${to.slice(7)}`
  const [ fh, fs, fv ] = convert.hex.hsv(from.slice(1, 7))
  const [ th, ts, tv ] = convert.hex.hsv(to.slice(1, 7))
  const [ r, g, b ] = convert.hsv.rgb(prp(fh, th, factor), prp(fs, ts, factor), prp(fv, tv, factor))
  return `#${convert.rgb.hex(r, g, b)}${`0${Math.round(prp(fa, ta, factor)).toString(16)}`.slice(-2)}`
}

const underline = (ctx, text, x, y, offset, size, active) => {
  const width = ctx.measureText(text).width
  ctx.beginPath()
  ctx.strokeStyle = STROKE_COLOR
  if (active === false) {
    ctx.strokeStyle += SEMI_TRANSPARENT
  }
  ctx.lineWidth = Math.trunc(size / 10)
  ctx.moveTo(x, y + offset)
  ctx.lineTo(x + width, y + offset)
  ctx.stroke()
}

const opaque = (ctx, text, x, y, offset, size, font, active) => {
  const { width, fontBoundingBoxAscent } = ctx.measureText(text)
  const long = font === 'underline' ? Math.trunc(size / 10) : 0
  ctx.fillStyle = '#ffffff'
  if (active === false) {
    ctx.fillStyle += SEMI_TRANSPARENT
  }
  ctx.fillRect(x - offset, y - offset - fontBoundingBoxAscent, offset * 2 + width, offset * 2 + size + long)
}

const drawTextLabel = (ctx, x, y, z, text, active) => {
  const font = `${text.size}px arial`
  ctx.font = font
  if (text.background === 'opaque') {
    opaque(ctx, text.label, x, y + +text.size + 2 + z / 2, 3, +text.size, text.font, active)
  }
  if (DIRECT_FONTS.indexOf(text.font) >= 0) {
    ctx.font = `${text.font} ${font}`
  } else if (text.font === 'underline') {
    underline(ctx, text.label, x, y + +text.size + 2 + z / 2, 3, +text.size, active)
  }
  if (text.background === 'halo') {
    ctx.fillStyle = '#ffffff'
    if (active === false) {
      ctx.fillStyle += SEMI_TRANSPARENT
    }
    ctx.fillText(text.label, x + 1, y + +text.size + 2 + z / 2 + 1)
    ctx.fillText(text.label, x - 1, y + +text.size + 2 + z / 2 - 1)
    ctx.fillText(text.label, x + 1, y + +text.size + 2 + z / 2 - 1)
    ctx.fillText(text.label, x - 1, y + +text.size + 2 + z / 2 + 1)
  }
  ctx.fillStyle = STROKE_COLOR
  if (active === false) {
    ctx.fillStyle += SEMI_TRANSPARENT
  }
  ctx.fillText(text.label, x, y + +text.size + 2 + z / 2)
}

export const siteRadius = (zoom, ratio = 4) => (2 + zoom / 2) * ratio / 4

/* export const previewSiteRenderer = (idContainer, width, height, ratio, zoom) => {
  const div = document.getElementById(idContainer)
  const id = `${idContainer}-preview`
  if (div) {
    div.innerHTML = `<canvas id="${id}" width="${width}" height="${height}"></canvas>`
    const ctx = document.getElementById(id)?.getContext('2d')
    ctx && directDrawSite(ctx, width / 2, height / 2, zoom, ratio)
  }
}

export const previewSectorRenderer = (idContainer, width, height, ratio = 4, zoom, a = 0, figure = 6) => {
  const div = document.getElementById(idContainer)
  const id = `${idContainer}-preview`
  if (div) {
    div.innerHTML = `<canvas id="${id}" width="${width}" height="${height}"></canvas>`
    const ctx = document.getElementById(id)?.getContext('2d')
    ctx &&
    directDrawSector(
      ctx,
      width / 2,
      height / 2,
      zoom,
      a,
      undefined,
      undefined,
      undefined,
      figure,
      undefined,
      undefined,
      0,
      undefined,
      ratio,
    )
  }
} */

// вывод иконки сектора в блок по idContainer и видом по индексу в figure
export const renderIconSector = (idContainer, figure) => {
  const div = document.getElementById(idContainer)
  const id = `${idContainer}-canvas`
  if (div) {
    div.innerHTML = `<canvas id="${id}" width="${DEF_WIDTH}" height="${DEF_HEIGHT}"></canvas>`
    const ctx = document.getElementById(id)?.getContext('2d')
    ctx && directDrawSector(ctx, 0, DEF_HEIGHT / 2, 12, 90, undefined, undefined, undefined, figure)
  }
}

export const directDrawSite = (ctx, x, y, z, ratio = 4, text, fill, active, selected) => {
  ctx.lineWidth = 0
  if (text && text.label) {
    drawTextLabel(ctx, x, y, z, text, active)
  }
  if (active) {
    ctx.lineWidth = 3
    ctx.strokeStyle = ACTIVE_STROKE_COLOR
  } else {
    ctx.lineWidth = 1
    ctx.strokeStyle = STROKE_COLOR
  }
  ctx.fillStyle = fill || DEFAULT_COLORS.SITE
  if (selected) {
    ctx.fillStyle = SELECTED_FILL_COLOR
    ctx.strokeStyle = SELECTED_STROKE_COLOR
  } else if (active === false) {
    ctx.strokeStyle += SEMI_TRANSPARENT
    ctx.fillStyle += SEMI_TRANSPARENT
  }
  ctx.beginPath()
  ctx.arc(x, y, siteRadius(z, ratio), 0, 2 * Math.PI)
  ctx.fill()
  ctx.stroke()
}

export const directDrawComplaint = (ctx, x, y, z, ratio = 4, text, fill) => {
  ctx.lineWidth = 0.75
  ctx.strokeStyle = fill ? calculateColor(fill, '#000000ff', 0.4) : COMPLAINT_STROKE_COLOR
  if (text && text.label) {
    drawTextLabel(ctx, x - ratio * 2, y + ratio * 3, z, text)
  }
  ctx.fillStyle = fill || DEFAULT_COLORS.COMPLAINT
  ctx.beginPath()
  ctx.arc(x, y, ratio * 2 + 0.5, 0, 2 * Math.PI)
  ctx.fill()
  ctx.stroke()
}

export const directDrawVectorLabel = (ctx, x, y, z, label) => {
  if (label) {
    drawTextLabel(ctx, x, y, z, label)
  }
}

export const directDrawVectorIcon = (ctx, x, y, z, ratio = 4, text, fill, active) => {
  ctx.lineWidth = 0
  if (text && text.label) {
    drawTextLabel(ctx, x, y, z, text, active)
  }
  if (active) {
    ctx.lineWidth = 3
    ctx.strokeStyle = ACTIVE_STROKE_COLOR
  } else {
    ctx.lineWidth = 1
    ctx.strokeStyle = STROKE_COLOR
  }
  ctx.fillStyle = fill || DEFAULT_COLORS.SITE
  if (active === false) {
    ctx.strokeStyle += SEMI_TRANSPARENT
    ctx.fillStyle += SEMI_TRANSPARENT
  }
  const offset = siteRadius(z * 2, ratio)
  ctx.beginPath()
  ctx.rect(x - offset / 2, y - offset / 2, offset, offset)
  ctx.fill()
  ctx.stroke()
}

export const drawSite = (marker, group, x, y, z) => {
  let active
  if (group.activeSite) {
    active = marker.options.id === group.activeSite
  }
  if (active) {
    z *= 2
  }
  let selected
  if (group.editNeighborsSite) {
    selected = marker.options.id === group.editNeighborsSite
  }
  directDrawSite(
    group._context,
    x, y, z,
    marker.options.ratio,
    z >= TEXT_LABELS_UNDER_ZOOM ? marker.options.label : null,
    marker.options.color,
    active,
    selected,
  )
}

export const drawComplaint = (marker, group, x, y, z) => {
  directDrawComplaint(
    group._context,
    x, y, z,
    marker.options.ratio,
    z >= TEXT_LABELS_UNDER_ZOOM ? marker.options.label : null,
    marker.options.color,
  )
}

export const drawVectorLabel = (marker, group, x, y, z) => {
  directDrawVectorLabel(
    group._context,
    x, y, z,
    marker.options.label,
  )
}

export const rotatePoints = (angle, points) => {
  const a = -angle / 180 * Math.PI
  const s = Math.sin(a)
  const c = Math.cos(a)
  return points.map(({ x, y }) => ({
    x: x * c - y * s,
    y: x * s + y * c,
  }))
}

export const beforeRedraw = (group) => {
  group._points = new Set()
}

const points = [
  { x: 0, y: 0 }, // 0
  { x: -15, y: 12.5 }, // 1
  { x: 0, y: 12.5 }, // 2
  { x: 15, y: 12.5 }, // 3
  { x: -15, y: 25 }, // 4
  { x: 0, y: 25 }, // 5
  { x: 15, y: 25 }, // 6
  { x: -15, y: 40 }, // 7
  { x: 0, y: 40 }, // 8
  { x: 15, y: 40 }, // 9
  { x: 0, y: 20 }, // 10
  { x: -9, y: 25 }, // 11
  { x: 9, y: 25 }, // 12
  { x: -3, y: 40 }, // 13
  { x: 3, y: 40 }, // 14
]

const radius6 = Math.sqrt((points[2].x - points[4].x) * (points[2].x - points[4].x) +
  (points[2].y - points[4].y) * (points[2].y - points[4].y))

const poly = (ctx, x, y, p, array) => {
  ctx.beginPath()
  ctx.moveTo(x + p[array[0]].x, y - p[array[0]].y)
  for (let i = 1; i < array.length; i++) {
    ctx.lineTo(x + p[array[i]].x, y - p[array[i]].y)
  }
  ctx.lineTo(x + p[array[0]].x, y - p[array[0]].y)
  ctx.closePath()
  ctx.fill()
  ctx.stroke()
}

const line = (ctx, x, y, p, idx1, idx2) => {
  ctx.moveTo(x + p[idx1].x, y - p[idx1].y)
  ctx.lineTo(x + p[idx2].x, y - p[idx2].y)
  ctx.stroke()
}

const drawSectorText = (ctx, x, y, z, pt, text, textPoints, offset = 3, active) => {
  const key = `${Math.round(x + pt.x)}:${Math.round(y - pt.y)}`
  const number = 1 + (textPoints[key] || 0)
  textPoints[key] = number
  drawTextLabel(ctx, x + pt.x, y - pt.y - number * text.size + offset, z, text, active)
}

// мемоизация координат при смене зума
let prevScale = null
let memoizePoints = points

const scalingPoints = (points, scale) => {
  if (prevScale !== scale) {
    prevScale = scale
    memoizePoints = points.map(({ x, y }) => {
      return { x: x * scale, y: y * scale }
    })
  }
  return memoizePoints
}

// мемоизация координат при повороте
let memoizeRotetePoints = {}
let prevRotateScale = null

const getRotatePoints = (angle, points, scale) => {
  if (prevRotateScale !== scale) {
    memoizeRotetePoints = {}
    prevRotateScale = scale
  }
  if (!memoizeRotetePoints[angle]) {
    memoizeRotetePoints[angle] = rotatePoints(angle, points)
  }
  return memoizeRotetePoints[angle]
}

export const scaleSector = (z, ratio = 4) => (0.25 + z / 36) * ratio / 4

export const directDrawSector = (
  ctx, x, y, z, a, b, text, fill, figure, technology, textPoints, offset = 0, active, ratio = 4,
) => {
  if (figure == null) {
    return
  }
  const scale = scaleSector(z, ratio)
  const pointsRatio = scalingPoints(points, scale)
  // const pArr = offset ? pointsRatio.map(({ x, y }) => ({ x, y: y ? y + offset : 0 })) : pointsRatio
  // const p = rotatePoints(a, pArr)
  const p = offset
    ? rotatePoints(a, pointsRatio.map(({ x, y }) => ({ x, y: y ? y + offset : 0 })))
    : getRotatePoints(a, pointsRatio, scale)

  if (active) {
    ctx.lineWidth = 3
    ctx.strokeStyle = ACTIVE_STROKE_COLOR
  } else {
    ctx.lineWidth = 1
    ctx.strokeStyle = STROKE_COLOR
  }
  ctx.fillStyle = fill || SECTOR_COLORS[technology] || DEFAULT_COLORS.SECTOR
  if (active === false) {
    ctx.strokeStyle += SEMI_TRANSPARENT
    ctx.fillStyle += SEMI_TRANSPARENT
  }

  const tps = drawSectorDetails[figure](p, ctx, x, y, a, scale)
  let tp = p[tps[0]]
  for (let i = 1; i < tps.length; i++) {
    if (p[tps[i]].y < tp.y) {
      tp = p[tps[i]]
    }
  }
  if (b) {
    ctx.lineTo(b.x, b.y)
  }
  ctx.stroke()

  if (text && textPoints && z >= TEXT_LABELS_UNDER_ZOOM) {
    drawSectorText(ctx, x, y, z, tp, text, textPoints, 3, active)
  }
}

export const drawSector = (marker, group, x, y, z) => {
  let { id, site, angle, basement, color, label, figure, technology, ratio } = marker.options

  if (basement) {
    basement = marker._map.latLngToContainerPoint(basement)
  }
  const offset = group.activeSite?.id === site ? group.activeSite.techOffset[technology] : 0
  let active
  if (group.activeSite) {
    if (group.activeSite.activeSector === id) {
      active = true
    } else if (group.activeSite.id !== site) {
      active = false
    }
  }
  directDrawSector(group._context, x, y, z, angle, basement, label, color, figure, technology, group._points,
    offset + siteRadius(z, ratio), active, ratio)
}

const drawSectorDetails = {
  U: (p, ctx, x, y) => {
    line(ctx, x, y, p, 13, 14)
    line(ctx, x, y, p, 11, 12)
    line(ctx, x, y, p, 1, 3)
    ctx.moveTo(x + p[8].x, y - p[8].y)
    ctx.lineTo(x + p[0].x, y - p[0].y)
    return [ 1, 3, 13, 14 ]
  },
  0: (p, ctx, x, y) => {
    ctx.moveTo(x + p[8].x, y - p[8].y)
    ctx.lineTo(x + p[0].x, y - p[0].y)
    return [ 8 ]
  },
  1: (p, ctx, x, y) => {
    poly(ctx, x, y, p, [ 5, 7, 9 ])
    ctx.lineTo(x + p[0].x, y - p[0].y)
    return [ 7, 9 ]
  },
  2: (p, ctx, x, y) => {
    poly(ctx, x, y, p, [ 2, 7, 9 ])
    ctx.lineTo(x + p[0].x, y - p[0].y)
    return [ 7, 9 ]
  },
  3: (p, ctx, x, y) => {
    poly(ctx, x, y, p, [ 2, 1, 8, 3 ])
    ctx.lineTo(x + p[0].x, y - p[0].y)
    return [ 1, 3, 8 ]
  },
  4: (p, ctx, x, y) => {
    poly(ctx, x, y, p, [ 10, 1, 8, 3 ])
    ctx.lineTo(x + p[0].x, y - p[0].y)
    return [ 1, 3, 8 ]
  },
  5: (p, ctx, x, y) => {
    poly(ctx, x, y, p, [ 2, 1, 5, 3 ])
    ctx.lineTo(x + p[0].x, y - p[0].y)
    return [ 1, 3, 5 ]
  },
  6: (p, ctx, x, y, a, s) => {
    const r = radius6 * s // 19.53 * s
    ctx.beginPath()
    ctx.moveTo(x + p[2].x, y - p[2].y)
    ctx.lineTo(x + p[4].x, y - p[4].y)
    ctx.arc(
      x + p[2].x,
      y - p[2].y,
      r,
      Math.PI * (a / 180 + 1.25),
      Math.PI * (a / 180 + 1.75),
    )
    ctx.lineTo(x + p[2].x, y - p[2].y)
    ctx.closePath()
    ctx.fill()
    ctx.stroke()
    ctx.lineTo(x + p[0].x, y - p[0].y)
    return [ 4, 6 ]
  },
  7: (p, ctx, x, y, a, s) => {
    const r = radius6 * s // 19.53 * s
    ctx.beginPath()
    ctx.moveTo(x + p[2].x, y - p[2].y)
    ctx.lineTo(x + p[4].x, y - p[4].y)
    ctx.lineTo(x + p[7].x, y - p[7].y)
    ctx.arc(
      x + 2 * p[8].x - p[5].x,
      y - 2 * p[8].y + p[5].y,
      r,
      Math.PI * (a / 180 + 0.75),
      Math.PI * (a / 180 + 0.25),
      true,
    )
    ctx.lineTo(x + p[6].x, y - p[6].y)
    ctx.lineTo(x + p[2].x, y - p[2].y)
    ctx.closePath()
    ctx.fill()
    ctx.stroke()
    ctx.lineTo(x + p[0].x, y - p[0].y)
    return [ 4, 7, 9, 6 ]
  },
  8: (p, ctx, x, y) => {
    poly(ctx, x, y, p, [ 2, 1, 7, 10, 9, 3 ])
    ctx.lineTo(x + p[0].x, y - p[0].y)
    return [ 1, 7, 9, 3 ]
  },
  9: (p, ctx, x, y) => {
    poly(ctx, x, y, p, [ 2, 11, 8, 12 ])
    ctx.lineTo(x + p[0].x, y - p[0].y)
    return [ 11, 8, 12 ]
  },
  // При додаванні нової рутини актуалізувати константу PATTERN_COUNT
  // (модуль /src/components/Panels/Customization/MapStyle/IconSector.js)
}

export const midToColor = (color = '') => {
  if (color?.charAt(0) !== '#') {
    const [ r, g, b ] = `${color}`.split(' ').map(Number)
    return `rgb(${r}, ${g}, ${b})`
  } else {
    return color
  }
}
