import React, { useCallback, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import TopNavMenu from '../components/TopNavMenu'
import { selectUserAccess } from '../features/login/loginSlice'
import { setPanel, selectPanel } from '../features/panel/panelSlice'
import { selectIsDefaultProject, selectIsSharedProject } from '../features/projects/projectsSlice'
import { TOP_NAV_MENU } from '../constants/menus'
import { EDIT_ELEMENTS, EDIT_POLYGONS, EXPORT_TABLE_DATA } from '../constants/access'
import { crumbsByPath, KEYS } from '../constants/routes'
import useWithSectorCoverage from '../hooks/useWithSectorCoverage'

const NO_REPLACE_IN_DEFAULT_PROJECT = [
  KEYS.SITES,
  KEYS.RBS,
  KEYS.SECTORS,
  KEYS.BUSINESS_CASES,
  KEYS.CREATE_BUSINESS_CASE,
]

const Menu = () => {
  const dispatch = useDispatch()

  const panel = useSelector(selectPanel)
  const userAccess = useSelector(selectUserAccess, shallowEqual)
  const isSharedProject = useSelector(selectIsSharedProject)
  const isDefaultProject = useSelector(selectIsDefaultProject)

  // const section = sectionByPath(window.location.pathname)
  const { section, objectId } = crumbsByPath(window.location.pathname)

  const clickTopNavItem = useCallback((key) => {
    dispatch(setPanel(key))
  }, [ dispatch ])

  const { isShowSectorsCoverage } = useWithSectorCoverage()

  const topNavItems = useMemo(() => {
    let module = section
    if (objectId && section === KEYS.BUSINESS_CASES) {
      module = KEYS.CREATE_BUSINESS_CASE
    }
    let items = TOP_NAV_MENU[module] ?? []
    if (!userAccess[EDIT_ELEMENTS] || isSharedProject ||
        (isDefaultProject && NO_REPLACE_IN_DEFAULT_PROJECT.includes(module))) {
      items = items.filter((item) => item.key !== 'replace')
    }
    if (!userAccess[EDIT_POLYGONS]) {
      items = items.filter((item) => item.key !== 'zones')
    }
    if (!userAccess[EXPORT_TABLE_DATA]) {
      items = items.filter((item) => item.key !== 'export' && item.key !== 'import')
    }
    if (!isShowSectorsCoverage) {
      items = items.filter((item) => item.key !== 'coverage')
    }

    return items.map(({ key, text, iconName, type }) => ({
      key,
      text,
      iconName,
      type,
      onClick: clickTopNavItem,
      pressed: panel === key,
    }))
  }, [
    clickTopNavItem, panel, section, objectId, userAccess, isSharedProject, isDefaultProject,
    isShowSectorsCoverage,
  ])

  return (
    <TopNavMenu items={topNavItems} />
  )
}

export default Menu
