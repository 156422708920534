import { createContext } from 'react'
import { empty } from '../../utils/format'

const HeaderContext = createContext({
  importMenu: [],
  setImportMenu: empty,
  exportMenu: [],
  setExportMenu: empty,
  filterMenu: [],
  setFilterMenu: empty,
  taskLogActions: {},
  setTaskLogActions: empty,
  trfActions: {},
  setTrfActions: empty,
  macroIndicatorActions: {},
  setMacroIndicatorActions: empty,
  businessCasesActions: {},
  setBusinessCasesActions: empty,
  notificationsActions: {},
  setNotificationsActions: empty,
  networkItemsActions: {},
  setNetworkItemsActions: empty,
  coverageMenu: [],
  setCoverageMenu: empty,
})

export default HeaderContext
