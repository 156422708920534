import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  removeNotifications, updateNotifications, loadNotifications, selectNotifications, selectNotificationsFields,
} from '../../features/notifications/notificationsSlice'
import * as Constants from '../../features/notifications/constants'
import { findIndex } from '../../features/network/indexing'
import HeaderContext from '../../layout/context/HeaderContext'
import Table from '../common/Table'
import { DATA_TYPES } from '../../constants/common'
import { useConfirm } from '../common/Confirm'
import { doGetAsyncReportResult, doSaveBCCalculationDetails } from '../../features/reports/reportsSlice'
import { activeProjectId } from '../../features/projects/projectsSlice'
import { selectUser } from '../../features/login/loginSlice'
import { REPORT_BC_CALCULATION_DETAILS, REPORT_NAMES } from '../../constants/reports'

import './Notifications.css'

const sizeOf = (bytes, precision = 2) => {
  if (!bytes) {
    return '0.00 B'
  }
  const e = Math.floor(Math.log(bytes) / Math.log(1024))
  return `${(bytes / Math.pow(1024, e)).toFixed(precision)} ${' KMGTP'.charAt(e)}B`
}

const NotificationsComponent = ({ setNotificationsActions }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const gridRef = useRef()
  const { renderConfirm, confirm, msg } = useConfirm()

  const data = useSelector(selectNotifications, shallowEqual)
  const user = useSelector(selectUser)
  const fields = useSelector(selectNotificationsFields, shallowEqual)
  const currentProjectId = useSelector(activeProjectId)

  const [ contextMenuDisabled, setContextMenuDisabled ] = useState({
    setRead: true,
    setUnread: true,
    deleteItems: true,
  })

  const [
    idFldIdx,
    statusFldIdx,
    typeFldIdx,
    reportIdFldIdx,
    reportTypeFldIdx,
    reportErrorFldIdx,
    projectIdFldIdx,
    projectNameFldIdx,
    bcNameFldIdx,
    contentSizeFldIdx,
  ] = useMemo(() => fields
    ? [
        findIndex(fields, Constants.NOTIFICATIONS_ID_FIELD),
        findIndex(fields, Constants.NOTIFICATIONS_STATUS_FIELD),
        findIndex(fields, Constants.NOTIFICATIONS_TYPE_FIELD),
        findIndex(fields, Constants.NOTIFICATIONS_REPORT_ID_FIELD),
        findIndex(fields, Constants.NOTIFICATIONS_REPORT_TYPE_FIELD),
        findIndex(fields, Constants.NOTIFICATIONS_ERROR_FIELD),
        findIndex(fields, Constants.NOTIFICATIONS_PROJECT_ID_FIELD),
        findIndex(fields, Constants.NOTIFICATIONS_PROJECT_NAME_FIELD),
        findIndex(fields, Constants.NOTIFICATIONS_BC_NAME_FIELD),
        findIndex(fields, Constants.NOTIFICATIONS_CONTENT_SIZE_FIELD),
      ]
    : [],
  [ fields ])

  const onLinkClick = useCallback((id, unread, path) => async (e) => {
    if (unread) {
      const res = await dispatch(updateNotifications(Constants.STATUS_READ, [ id ]))
      if (!res.error) {
        await loadNotifications(dispatch, user, data, currentProjectId)
      }
    }
    if (path) {
      e.preventDefault()
      history.push(path)
    } else {
      document.getElementById(e.target.id).click()
    }
  }, [ dispatch, history, user, data, currentProjectId ])

  const onReportClick = useCallback((notificationId, reportId, reportType, unread, projectId, projectName, bcName) =>
    async (event) => {
      event.preventDefault()
      const current = currentProjectId ?? '_'
      if (current !== projectId && reportType !== REPORT_BC_CALCULATION_DETAILS) {
        confirm(
          () => {
            window.open(`/${projectId}/notifications`, '_blank', 'noopener,noreferrer')
          },
          {
            title: 'Confirmation',
            messages: [
              `This Report was generated in Project "${projectName}".`,
              'You need to load this Project in order to view the Report.',
            ],
            textYesBtn: 'Load Project',
            textNoBtn: 'Cancel',
          },
        )
      } else {
        if (reportType === REPORT_BC_CALCULATION_DETAILS) {
          dispatch(doSaveBCCalculationDetails(reportType, notificationId, bcName))
        } else {
          dispatch(doGetAsyncReportResult(reportType, reportId))
        }
        if (unread) {
          const res = await dispatch(updateNotifications(Constants.STATUS_READ, [ notificationId ]))
          if (!res.error) {
            loadNotifications(dispatch, user, data, projectId)
          }
        }
      }
    }, [ dispatch, user, data, currentProjectId, confirm ])

  const linkRenderer = useCallback((unread) => (instance, td, row, col, prop, value) => {
    if (value) {
      const { id, href, label, newTab } = value

      td.innerHTML = href === null
        ? label
        : (newTab
            ? `<a id="${id}" href='${href}' target='_blank' rel='noreferrer'>${label}</a>`
            : `<a id="${id}" href='${href}' rel='noreferrer'>${label}</a>`)

      const element = document.getElementById(id)
      if (element) {
        element.addEventListener('mouseup', onLinkClick(+id?.replace('bc_', ''), unread, !newTab && href), false)
      }
    } else {
      td.innerHTML = ''
    }
    td.className = 'read-only-cell'
  }, [ onLinkClick ])

  const buttonRenderer = useCallback((id, reportType, reportId, unread, projectId, projectName, bcName, contentSize) =>
    (instance, td, row, col, prop, value, cellProperties) => {
      td.style.whiteSpace = 'nowrap'
      td.className = 'read-only-cell'
      td.innerHTML = `<a href="#" id="notification-button-${id}">${
        (REPORT_NAMES[reportType] ?? 'Report').replace(' ', '&nbsp;')
      }</a>${
        contentSize
          ? ` <span class="notification__content-size">${sizeOf(contentSize)}</span>`
          : ''
      }`
      document.getElementById(`notification-button-${id}`)
        .addEventListener('mouseup', onReportClick(id, reportId, reportType, unread, projectId, projectName, bcName))
    }, [ onReportClick ])

  const nullRenderer = useCallback((instance, td, row, col, prop, value, cellProperties) => {
    td.className = 'read-only-cell'
    td.innerHTML = ' '
  }, [])

  const errorRenderer = useCallback((msg) => (instance, td, row, col, prop, value, cellProperties) => {
    td.style.color = 'red'
    td.className = 'read-only-cell'
    td.innerHTML = `<span>${msg}</span>`
  }, [])

  const cells = useCallback((row, col) => {
    const cellProperties = {}
    const table = gridRef.current?.hotInstance
    if (!table) {
      return cellProperties
    }
    const physicalRow = table.toPhysicalRow(row)
    const physicalCol = table.toPhysicalColumn(col)
    const id = fields[physicalCol].id
    const unread = table.getDataAtCell(physicalRow, statusFldIdx) === Constants.STATUS_UNREAD
    const notificationId = table.getDataAtCell(physicalRow, idFldIdx)
    if (id === Constants.NOTIFICATIONS_PROJECT_PATH_FIELD || id === Constants.NOTIFICATIONS_BC_PATH_FIELD) {
      cellProperties.renderer = linkRenderer(unread)
    } else if (id === Constants.NOTIFICATIONS_REPORT_ID_FIELD) {
      const rowType = table.getDataAtCell(physicalRow, typeFldIdx)
      const error = table.getDataAtCell(physicalRow, reportErrorFldIdx)
      if (error) {
        cellProperties.renderer = errorRenderer(error)
      } else if ([ Constants.TYPE_BC_CALCULATE_SNC_COMPLETED, Constants.TYPE_REPORT_COMPLETED ].includes(rowType)) {
        const reportId = table.getDataAtCell(physicalRow, reportIdFldIdx)
        const reportType = table.getDataAtCell(physicalRow, reportTypeFldIdx)
        const projectId = table.getDataAtCell(physicalRow, projectIdFldIdx)
        const projectName = table.getDataAtCell(physicalRow, projectNameFldIdx)
        const bcName = table.getDataAtCell(physicalRow, bcNameFldIdx)
        const contentSize = table.getDataAtCell(physicalRow, contentSizeFldIdx)
        cellProperties.renderer = buttonRenderer(notificationId, reportType, reportId,
          unread, projectId, projectName, bcName, contentSize)
      } else {
        cellProperties.renderer = nullRenderer
      }
    }

    if (unread) {
      try {
        const cell = table.getCell(row, col)
        if (cell) {
          cell.style.fontWeight = 'bold'
        }
      } catch (err) {
        // Ігноруємо помилку (table.getCell викине помилку, якщо комірка не в полі видимості,
        // але нам тоді й не потрібно її виділяти
      }
    }

    return cellProperties
  }, [
    fields, linkRenderer, buttonRenderer, idFldIdx, statusFldIdx, typeFldIdx, reportIdFldIdx, reportTypeFldIdx,
    nullRenderer, reportErrorFldIdx, errorRenderer, projectIdFldIdx, projectNameFldIdx, bcNameFldIdx, contentSizeFldIdx,
  ])

  const onCallbackContextMenu = useCallback(async (key) => {
    switch (key) {
      case 'setRead':
      case 'setUnread':
      case 'deleteItems': {
        const table = gridRef.current?.hotInstance
        let [ [ firstRow,, lastRow ] ] = table?.getSelected() || [ [] ]
        if (firstRow > lastRow) {
          [ firstRow, lastRow ] = [ lastRow, firstRow ]
        }
        const notifications = []
        for (let row = firstRow; row <= lastRow; ++row) {
          const id = table.getDataAtCell(row, idFldIdx)
          notifications.push(id)
        }

        if (key === 'deleteItems') {
          const doRemove = async () => {
            const res = await dispatch(removeNotifications(notifications))
            if (!res.error) {
              loadNotifications(dispatch, user, data, currentProjectId)
            }
          }
          confirm(
            doRemove,
            {
              title: 'Confirmation',
              messages: [ 'The selected notification(s) will be removed. This operation cannot be undone.' ],
              textYesBtn: 'Remove',
              textNoBtn: 'Cancel',
            },
          )
        } else {
          const newStatus = key === 'setRead' ? Constants.STATUS_READ : Constants.STATUS_UNREAD
          const res = await dispatch(updateNotifications(newStatus, notifications))
          if (!res.error) {
            loadNotifications(dispatch, user, data, currentProjectId)
            setContextMenuDisabled({
              ...contextMenuDisabled,
              setRead: newStatus === Constants.STATUS_READ,
              setUnread: newStatus === Constants.STATUS_UNREAD,
            })
          }
        }
        break
      }
      default:
    }
  }, [
    dispatch, idFldIdx, confirm, user, data, currentProjectId,
    setContextMenuDisabled, contextMenuDisabled,
  ])

  const onSelect = useCallback(() => {
    const table = gridRef.current?.hotInstance
    let setRead = true
    let setUnread = true
    let deleteItems = true
    if (table) {
      const selected = table.getSelected()
      if (selected && selected[0]) {
        deleteItems = false
        const [ rowStart,, rowEnd ] = selected[0]
        const min = Math.min(rowStart, rowEnd)
        const max = Math.max(rowStart, rowEnd)
        for (let row = min; row <= max; ++row) {
          const idx = table.toPhysicalRow(row)
          const status = data[idx]?.[statusFldIdx]
          if (status === Constants.STATUS_READ) {
            setUnread = false
          } else {
            setRead = false
          }
        }
      }
      setContextMenuDisabled({
        setRead,
        setUnread,
        deleteItems,
      })
    }
  }, [ data, statusFldIdx ])

  const handleRemoveAll = useCallback(() => {
    const doRemove = async () => {
      const res = await dispatch(removeNotifications(notifications))
      if (!res.error) {
        return loadNotifications(dispatch, user, data, currentProjectId)
      }
    }

    const notifications = data?.map((row) => row[idFldIdx])
    if (notifications.length > 0) {
      confirm(
        doRemove,
        {
          title: 'Confirmation',
          messages: [ 'All notifications will be removed. This operation cannot be undone.' ],
          textYesBtn: 'Remove',
          textNoBtn: 'Cancel',
        },
      )
    } else {
      msg({
        messages: [ 'There are no notifications to remove.' ],
      })
    }
  }, [ dispatch, data, idFldIdx, confirm, msg, user, currentProjectId ])

  const notificationsActions = useMemo(() => ({
    removeAll: handleRemoveAll,
    removeAllDisabled: false,
    removeAllHidden: false,
  }), [ handleRemoveAll ])

  useEffect(() => {
    setNotificationsActions && setNotificationsActions(notificationsActions)
  }, [ notificationsActions, setNotificationsActions ])

  return (
    <div className={'capex-notifications'}>
      {data && fields && (
        <Table
          refHot={gridRef}
          dataType={DATA_TYPES.NOTIFICATIONS}
          data={data}
          columns={fields}
          cells={cells}
          columnSorting={false}
          onCallbackContextMenu={onCallbackContextMenu}
          contextMenuDisabled={contextMenuDisabled}
          onSelectionEnd={onSelect}
          readOnly
        />
      )}
      {renderConfirm()}
    </div>
  )
}

const Notifications = () => (
  <HeaderContext.Consumer>
    {({ setNotificationsActions }) => (
      <NotificationsComponent setNotificationsActions={setNotificationsActions} />
    )}
  </HeaderContext.Consumer>
)

export default Notifications
