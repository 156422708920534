import { fetch } from './rest'

export const load = () => fetch('/api/projects')
  .then((data) => {
    if (data && data.length > 0) {
      return data
    }
    return []
  })

export const loadProject = ({ projectId }) =>
  fetch(`/api/project/${projectId}`, 'GET')
    .then((data) => {
      const store = window.store?.getState()
      const userId = store?.login.user?.id || 'anonymous'
      localStorage.setItem(`last_project_id_${userId}`, projectId)
      return data || {}
    })

export const create = (project) =>
  fetch('/api/project', 'POST', project)

export const remove = (projectId) =>
  fetch(`/api/project/${projectId}`, 'DELETE')

export const grantAccess = ({ projectId, users }) =>
  fetch(`/api/project/${projectId}/grant`, 'PUT', { users })

export const getProjectGrants = (projectId) =>
  fetch(`/api/project/${projectId}/grants`, 'GET')

export const revokeAccess = (projectId) =>
  fetch(`/api/project/${projectId}/revoke`, 'PUT')

export const revokesAccess = ({ projectId, users }) =>
  fetch(`/api/project/${projectId}/revokes`, 'PUT', { userIds: users })

export const copy = ({ projectId, name, description }) =>
  fetch(`/api/project/${projectId}/copy`, 'POST', { name, description })

export const update = ({ projectId, data }) =>
  fetch(`/api/project/${projectId}`, 'PUT', data)

export const getProjectProperties = (projectId) =>
  fetch(`/api/project/${projectId || '_'}/properties`, 'GET')

export const changeOwner = ({ projectIds, projectOwner }) =>
  fetch('/api/projects/owners', 'PATCH', { projectOwner, projectIds })
