import { empty } from '../../utils/format'
import { BCNeighborsInfo } from '../../features/bc/bcSlice'

export const askDataWillBeLost = (ask, actionOk, actionBack) => {
  ask(
    actionOk,
    actionBack,
    null,
    {
      title: 'Confirmation',
      messages: [ 'Unsaved changes on the form will be lost' ],
      textYesBtn: 'Ok',
      textNoBtn: 'Cancel',
    },
  )
}

export const confirmNotAllSelected = (confirm, action) => {
  confirm(
    action,
    {
      title: 'Confirmation',
      messages: [
        'Not all BCs are selected!',
        'Do you wish to Continue?',
      ],
      textYesBtn: 'Yes',
      textNoBtn: 'No',
    },
  )
}

export const askSitesErrors = (ask, unknownSites, sitesWithoutSectors, continueAction, cancelAction) => {
  const messages = []
  if (unknownSites && unknownSites.length > 0) {
    messages.push(`'${unknownSites.join(', ')}' not found in the project`)
  }
  if (sitesWithoutSectors && sitesWithoutSectors.length > 0) {
    const dont = sitesWithoutSectors.length === 1 ? 'does' : 'do'
    messages.push(`'${sitesWithoutSectors.join(', ')}' ${dont} not contain sectors available for calculation`)
  }
  ask(
    continueAction,
    cancelAction,
    null,
    {
      title: 'Confirmation',
      messages,
      textYesBtn: 'Back',
      textNoBtn: 'Exit',
      textCancelBtn: null,
    },
  )
}

export const askNotFieldsReady = (ask, fields, actionContinue, actionCancel) => {
  ask(
    actionContinue,
    actionCancel,
    null,
    {
      title: 'Confirmation',
      messages: [
        `'${fields.join(', ')}' are not filled`,
      ],
      textYesBtn: 'Back',
      textNoBtn: 'Exit',
    },
  )
}

export const askSaveBC = (ask, actionSave, actionCancel) => {
  ask(
    actionSave,
    actionCancel,
    null,
    {
      title: 'Confirmation',
      messages: [
        'Do you want to save your BC?',
      ],
      textYesBtn: 'Yes',
      textNoBtn: 'No',
    },
  )
}

export const askShowStatistics = (ask, techData, actionRun, actionCancel, subTitle) => {
  ask(
    actionRun,
    actionCancel,
    null,
    {
      title: 'Statistics',
      subTitle: subTitle || 'Will be sent for calculation',
      messages: techData,
      textYesBtn: 'Run',
      textNoBtn: 'Back',
      allowContentComponent: true,
    },
  )
}

export const errorMessage = (ask, message) => {
  ask(
    empty,
    null,
    null,
    {
      title: 'Error',
      subTitle: '',
      messages: [
        message,
      ],
      textYesBtn: 'Ok',
      textNoBtn: 'Cancel',
      allowContentComponent: true,
    },
  )
}

export const notUniqueMessage = (ask, names, actionClose) => {
  ask(
    actionClose,
    null,
    null,
    {
      title: 'Error',
      messages: [
        `${names?.join(', ')} ${names?.length > 1 ? 'are' : 'is'} already used or does not exist.`,
      ],
      textYesBtn: 'Ok',
      textNoBtn: 'Cancel',
      allowContentComponent: true,
    },
  )
}

export const copyToClipboard = (refHot, selection, copyJSON) => {
  if (refHot.current) {
    const table = refHot.current.hotInstance
    const [ { start: { row, col } } ] = selection
    let selected = table.getDataAtCell(row, col)
    if (selected instanceof BCNeighborsInfo) {
      selected = copyJSON
        ? JSON.stringify(selected.data, null, 2)
        : selected.list
    }
    navigator.clipboard.writeText(selected)
  }
}

export const getNeighborSectorNames = (refHot, selection) => {
  if (refHot.current) {
    const table = refHot.current.hotInstance
    const [ { start: { row, col } } ] = selection
    const selected = table.getDataAtCell(row, col)
    if (selected && selected.list) {
      return selected.list.split('\n')
    }
  }

  return null
}

export const getSelectedRow = (refHot, selection) => {
  if (refHot.current) {
    const table = refHot.current.hotInstance
    const [ { start: { row } } ] = selection
    return table?.toPhysicalRow(row)
  }

  return -1
}
