import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { activeProjectId } from '../features/projects/projectsSlice'
import {
  loadSectorsCoverage,
  removeSectorsCoverage,
  selectCoverage,
  selectCoverageSiteIds,
  selectErrorMessages,
  selectIsLoadingCoverage,
  selectUpdateFilters,
  setErrorMessages,
} from '../features/withSectorsCoverage/withSectorsCoverageSlice'

const useWithSectorCoverage = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const projectId = useSelector(activeProjectId)
  const isLoading = useSelector(selectIsLoadingCoverage)
  const coverage = useSelector(selectCoverage)
  const siteIds = useSelector(selectCoverageSiteIds)
  const removeAllFilters = useSelector(selectUpdateFilters)
  const errorMessages = useSelector(selectErrorMessages)

  const showCoverageForSectors = useCallback(({ sectors, siteId, updateFilters = true, openMap = true }) => {
    const showOnMap = () => {
      history.push(`/${projectId || '_'}/map`)
    }

    dispatch(loadSectorsCoverage({ sectors, siteId, showOnMap: openMap && showOnMap, updateFilters }))
  }, [ dispatch, history, projectId ])

  const removeCoverageForSectors = useCallback(() => {
    dispatch(removeSectorsCoverage(removeAllFilters))
  }, [ dispatch, removeAllFilters ])

  const handleSetErrorMessages = useCallback((messages) => {
    dispatch(setErrorMessages(messages))
  }, [ dispatch ])

  return {
    isShowSectorsCoverage: !!coverage,
    isLoadingSectorsCoverage: isLoading,
    sectorsCoverage: coverage,
    sectorsSiteIds: siteIds,
    errorMessages,
    setSectorsCoverageErrorMessages: handleSetErrorMessages,
    showSectorsCoverage: showCoverageForSectors,
    cancelShowCoverage: removeCoverageForSectors,
  }
}

export default useWithSectorCoverage
