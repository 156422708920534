import React from 'react'
import * as ReactDOM from 'react-dom'
import Handsontable from 'handsontable'
import { mergeStyles, FontIcon as Icon } from '@fluentui/react'
import { cabinet, KEYS } from '../constants'
import { compareFunctionBoolean } from '../../Tables/utils'

const ro = true

export const TABLE_ID = 'table.projects'

export const COLUMNS = [
  {
    id: KEYS.ID,
    title: 'ID',
    label: 'ID',
    editable: !ro,
    readOnly: ro,
    hidden: true,
    // type: 'uuid',
  },
  {
    id: KEYS.CHECK,
    title: ' ',
    label: 'Select',
    type: 'checkbox',
    width: '40',
    editable: true,
    readOnly: false,
    className: 'align-center',
    columnSorting: {
      compareFunctionFactory: compareFunctionBoolean,
    },
  },
  {
    id: KEYS.PROJECT_NAME,
    title: cabinet.title[KEYS.PROJECT_NAME],
    label: cabinet.title[KEYS.PROJECT_NAME],
    type: 'text',
    editable: !ro,
    readOnly: ro,
    hidden: true,
  },
  {
    id: KEYS.DESCRIPTION,
    title: cabinet.title[KEYS.DESCRIPTION],
    label: cabinet.title[KEYS.DESCRIPTION],
    type: 'text',
    editable: true,
    readOnly: false,
    validator: Handsontable.validators.AutocompleteValidator,
  },
  {
    id: KEYS.DATE,
    title: 'Creation',
    label: 'Creation',
    type: 'date',
    subType: 'dateTime',
    dateFormat: 'YYYY-MM-DD HH:mm:ss',
    editable: !ro,
    readOnly: ro,
    className: 'align-center',
  },
  {
    id: KEYS.OWNER_NAME,
    title: 'Project owner',
    label: 'Project owner',
    type: 'text',
    editable: !ro,
    readOnly: ro,
  },
  {
    id: KEYS.OWNER_ID,
    title: 'Project owner ID',
    label: 'Project owner ID',
    editable: false,
    readOnly: true,
    hidden: true,
    type: 'text',
  },
  {
    id: KEYS.SHARED,
    title: 'Shared',
    label: 'Shared',
    type: 'boolean',
    editable: false,
    readOnly: true,
    hidden: true,
  },
  {
    id: KEYS.GRANTS,
    title: 'Shared with',
    label: 'Shared with',
    type: 'text',
    editable: false,
    readOnly: true,
  },
]

export const getColumnIndexById = (id) => COLUMNS.findIndex((column) => (column.id === id))

export const getColumnIdByIndex = (index) => COLUMNS[index].id

const iconClass = mergeStyles({
  marginTop: 1,
  marginRight: 8,
})

export const contextMenu = (handlingContextMenu, user) => ({
  className: 'CMTableProjects',
  style: {},
  items: {
    delete: {
      disabled () {
        const row = this.getSelectedLast()[0]
        const idUser = this.getDataAtCell(row, 6)
        return idUser !== user.id
      },
      name: 'Delete project',
      renderer: function (hot, wrapper) {
        const name = 'Delete project'
        ReactDOM.render(
          <div className="contextMenuItem">
            <Icon
              iconName={'Delete'}
              className={iconClass}
            />
            {name}
          </div>,
          wrapper)
        return wrapper
      },
      callback: function (e) {
        const select = this.getSelectedLast()
        if (Array.isArray(select) && select.length !== 0) {
          const row = select[0]
          const rowData = this.toPhysicalRow(row)
          const project = this.getSourceDataAtRow(rowData)
          if (project) {
            handlingContextMenu(e, project)
          }
        }
      },
    },
    copy: {
      name: 'Make a copy',
      renderer: function (hot, wrapper) {
        const name = 'Make a copy'
        ReactDOM.render(
          <div className="contextMenuItem">
            <Icon
              iconName={'Copy'}
              className={iconClass}
            />
            {name}
          </div>,
          wrapper)
        return wrapper
      },
      callback: function (e) {
        const select = this.getSelectedLast()
        if (Array.isArray(select) && select.length !== 0) {
          const row = select[0]
          const rowData = this.toPhysicalRow(row)
          const project = this.getSourceDataAtRow(rowData)
          if (project) {
            handlingContextMenu(e, project)
          }
        }
      },
    },
    share: {
      name: 'Share project',
      renderer: function (hot, wrapper) {
        const name = 'Share project'
        ReactDOM.render(
          <div className="contextMenuItem">
            <Icon
              iconName={'ShareiOS'}
              className={iconClass}
            />
            {name}
          </div>,
          wrapper)
        return wrapper
      },
      callback: function (e) {
        const select = this.getSelectedLast()
        if (Array.isArray(select) && select.length !== 0) {
          const row = select[0]
          const rowData = this.toPhysicalRow(row)
          const project = this.getSourceDataAtRow(rowData)
          if (project) {
            handlingContextMenu(e, project)
          }
        }
      },
    },
    separator: Handsontable.plugins.ContextMenu.SEPARATOR,
    cabinet: {
      name: 'cabinet',
      renderer () {
        const newDiv = document.createElement('div')
        const name = 'Cabinet'
        ReactDOM.render(
          <>
            <Icon
              iconName={'AccountManagement'}
              className={iconClass} />
            {name}
          </>,
          newDiv)
        return newDiv
      },
      disableSelection: true,
      isCommand: false,
    },
  },
})
