import { configureStore } from '@reduxjs/toolkit'
import loginReducer from './login/loginSlice'
import geoReducer from './geo/geoSlice'
import vectorReducer from './vector/vectorSlice'
import rasterReducer from './raster/rasterSlice'
import settingsReducer from './settings/settingsSlice'
import networkReducer from './network/networkSlice'
import projectsReducer from './projects/projectsSlice'
import usersReducer from './users/usersSlice'
import loadingReducer from './loading/loadingSlice'
import macroIndicatorsReducer from './macroIndicators/macroIndicatorsSlice'
import trfReducer from './trf/trfSlice'
import bcReducer from './bc/bcSlice'
import undoReducer from './undo/undoSlice'
import taskLogReducer from './taskLog/taskLogSlice'
import compositeIndexReducer from './compositeIndex/compositeIndexSlice'
import historicalDataReducer from './historicalData/historicalDataSlice'
import synchronizationReducer from './synchronization/synchronizationSlice'
import eventLogReducer from './eventLog/eventLogSlice'
import panelReducer from './panel/panelSlice'
import notificationsReducer from './notifications/notificationsSlice'
import reportsReducer from './reports/reportsSlice'
import metricsReducer from './metrics/metricsSlice'
import withSectorsCoverageReducer from './withSectorsCoverage/withSectorsCoverageSlice'

export const store = configureStore({
  reducer: {
    login: loginReducer,
    geo: geoReducer,
    vector: vectorReducer,
    raster: rasterReducer,
    settings: settingsReducer,
    network: networkReducer,
    projects: projectsReducer,
    users: usersReducer,
    loading: loadingReducer,
    macroIndicators: macroIndicatorsReducer,
    trf: trfReducer,
    bc: bcReducer,
    undo: undoReducer,
    taskLog: taskLogReducer,
    compositeIndex: compositeIndexReducer,
    historicalData: historicalDataReducer,
    synchronization: synchronizationReducer,
    eventLog: eventLogReducer,
    panel: panelReducer,
    notifications: notificationsReducer,
    reports: reportsReducer,
    metrics: metricsReducer,
    withSectorsCoverage: withSectorsCoverageReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    thunk: true,
    immutableCheck: false,
    serializableCheck: false,
  }),
})

window.store = store
