import React, { useRef } from 'react'
import { useBoolean } from '@fluentui/react-hooks'
import { ITEM_TYPE_CALL_ACTION, ITEM_TYPE_CONTEXT_MENU } from '../../constants/menus'
import HeaderContext from '../../layout/context/HeaderContext'
import ContextMenu from '../common/ContextMenu'
import TopNavButton from './TopNavButton'

import './TopNavMenu.css'

const TopNavButtonWithMenu = ({ id, iconName, text, items }) => {
  const buttonRef = useRef(null)

  const [ menuOpen, { setTrue: openMenu, setFalse: closeMenu } ] = useBoolean(false)

  return (
    <>
      <TopNavButton
        ref={buttonRef}
        id={id}
        iconName={iconName}
        text={text}
        onClick={openMenu}
        expanded={true}
        pressed={menuOpen}
        disabled={!items || !items.length}
      />
      <ContextMenu items={items} visible={menuOpen} onClose={closeMenu} target={buttonRef} />
   </>
  )
}

const TopNavMenu = ({ items }) => (
  <HeaderContext.Consumer>
    {(headerContext) => (
      <div className="top-nav-menu">
        {items.map(({ key, text, iconName, onClick, pressed, type }) => {
          switch (type) {
            case ITEM_TYPE_CONTEXT_MENU: {
              return (
                <TopNavButtonWithMenu
                  id={key}
                  key={key}
                  iconName={iconName}
                  text={text}
                  items={headerContext[`${key}Menu`]}
                />
              )
            }
            case ITEM_TYPE_CALL_ACTION: {
              const [ group, action ] = key.split('-')
              return headerContext[group]?.[`${action}Hidden`]
                ? null
                : (
                <TopNavButton
                  key={key}
                  id={key}
                  iconName={iconName}
                  text={text}
                  onClick={headerContext[group]?.[action]}
                  disabled={headerContext[group]?.[`${action}Disabled`]}
                  expanded={true}
                  pressed={pressed}
                />
                  )
            }
            default: {
              return (
                <TopNavButton
                  key={key}
                  id={key}
                  iconName={iconName}
                  text={text}
                  onClick={onClick}
                  expanded={true}
                  pressed={pressed}
                />
              )
            }
          }
        })}
      </div>
    )}
  </HeaderContext.Consumer>
)

export default TopNavMenu
