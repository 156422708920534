/* eslint-disable react/display-name */
import {
  addFilters as bcAddFilters,
  filterAllBCData,
  resetFilters as bcResetFilters,
} from '../bc/bcSlice'
import {
  addFilters as mapAttributesAddFilters,
  filterAllData as filterAllMapAttributesData,
  resetFilters as mapAttributesResetFilters,
  selectMapAttributes,
} from '../vector/vectorSlice'
import {
  addFilters as networkAddFilters,
  elementsRedraw,
  filterBaseStations,
  filterComplaints,
  filterSectors,
  filterSites,
  resetFilters as networkResetFilters,
  filterBaseStationsUp,
  filterSitesUp,
} from '../network/networkSlice'
import { DATA_TYPES } from '../../constants/common'
import {
  controlFilterSettings,
  selectSettingsDeepFiltrationUp,
  selectSettingsDeepFiltrationDown,
} from '../settings/settingsSlice'
import { EVENT_TYPE, saveEventLog } from '../eventLog/eventLogSlice'

export const selectFilters = (dataType) => (state) => {
  switch (dataType) {
    case DATA_TYPES.SECTORS: return state.network?.sectors?.filters
    case DATA_TYPES.SITES: return state.network?.sites?.filters
    case DATA_TYPES.BASE_STATIONS: return state.network?.baseStations?.filters
    case DATA_TYPES.COMPLAINTS: return state.network?.complaints?.filters
    case DATA_TYPES.BUSINESS_CASES: return state.bc?.businessCases?.filters
    case DATA_TYPES.CREATE_BUSINESS_CASES: return state.bc?.createBC?.filters
    default:
  }
  if (dataType?.startsWith(DATA_TYPES.MAP_ATTRIBUTES)) {
    return selectMapAttributes(dataType)(state)?.filters
  }
  return null
}

export const selectFiltersFiltering = (dataType) => (state) => {
  switch (dataType) {
    case DATA_TYPES.SECTORS: return state.network?.sectors?.filtering
    case DATA_TYPES.SITES: return state.network?.sites?.filtering
    case DATA_TYPES.BASE_STATIONS: return state.network?.baseStations?.filtering
    case DATA_TYPES.COMPLAINTS: return state.network?.complaints?.filtering
    case DATA_TYPES.BUSINESS_CASES: return state.bc?.businessCases?.filtering
    case DATA_TYPES.CREATE_BUSINESS_CASES: return state.bc?.createBC?.filtering
    default:
  }
  if (dataType?.startsWith(DATA_TYPES.MAP_ATTRIBUTES)) {
    return selectMapAttributes(dataType)(state)?.filtering
  }
  return null
}

export const saveFilterLog = (dataType, selector) => (dispatch, getState) => {
  const state = getState()
  const { full, list } = selector(state)
  return dispatch(saveEventLog(
    EVENT_TYPE.filtration,
    ` [Table = ${dataType}; Full = ${full.getList().length}; Filtered = ${list.getList().length}]`,
  ))
}

export const dataFilteringByType = (dataType) => async (dispatch, getState) => {
  if (!dataType) {
    return
  }
  switch (dataType) {
    case DATA_TYPES.SITES: {
      await dispatch(filterSites())
      break
    }
    case DATA_TYPES.SECTORS: {
      await dispatch(filterSectors())
      break
    }
    case DATA_TYPES.COMPLAINTS: {
      await dispatch(filterComplaints())
      break
    }
    case DATA_TYPES.BASE_STATIONS: {
      await dispatch(filterBaseStations())
      break
    }
    case DATA_TYPES.BUSINESS_CASES:
    case DATA_TYPES.CREATE_BUSINESS_CASES: {
      await dispatch(filterAllBCData(dataType))
      return
    }
    default: {
      if (dataType?.startsWith(DATA_TYPES.MAP_ATTRIBUTES)) {
        await dispatch(filterAllMapAttributesData(dataType))
      }
      return
    }
  }
  const currentState = getState()
  const deepFiltrationDown = selectSettingsDeepFiltrationDown(currentState)
  if (deepFiltrationDown) {
    if (dataType === DATA_TYPES.SITES) {
      await dispatch(filterBaseStations())
      await dispatch(filterSectors())
    } else if (dataType === DATA_TYPES.BASE_STATIONS) {
      await dispatch(filterSectors())
    }
  }
  const deepFiltrationUp = selectSettingsDeepFiltrationUp(currentState)
  if (deepFiltrationUp) {
    await dispatch(filterBaseStationsUp())
    await dispatch(filterSitesUp())
  }
  dispatch(elementsRedraw()) // for networks object
}

// функции установки фильтра для различных таблиц
export const addFilterToTable = ({ dataType, filter, reset, doNotSaveOnserver }) => async (dispatch) => {
  switch (dataType) {
    case DATA_TYPES.SECTORS:
    case DATA_TYPES.SITES:
    case DATA_TYPES.BASE_STATIONS:
    case DATA_TYPES.COMPLAINTS:
      await dispatch(networkAddFilters({ dataType, filter, reset }))
      break
    case DATA_TYPES.BUSINESS_CASES:
    case DATA_TYPES.CREATE_BUSINESS_CASES:
      await dispatch(bcAddFilters({ dataType, filter, reset }))
      break
    default:
      if (dataType.startsWith(DATA_TYPES.MAP_ATTRIBUTES)) {
        await dispatch(mapAttributesAddFilters({ dataType, filter, reset }))
        break
      }
      return
  }
  if (!doNotSaveOnserver) {
    dispatch(controlFilterSettings(dataType)) // сохранение изменений настроек фильтрации
  }
}

// функция сброса фильтра для различных таблиц
export const resetFilterInTable = (dataType) => async (dispatch, getState) => {
  const prevState = getState()

  switch (dataType) {
    case DATA_TYPES.SECTORS:
    case DATA_TYPES.SITES:
    case DATA_TYPES.BASE_STATIONS:
    case DATA_TYPES.COMPLAINTS: await dispatch(networkResetFilters(dataType))
      break
    case DATA_TYPES.BUSINESS_CASES:
    case DATA_TYPES.CREATE_BUSINESS_CASES:
      await dispatch(bcResetFilters(dataType))
      break
    default:
      if (dataType.startsWith(DATA_TYPES.MAP_ATTRIBUTES)) {
        await dispatch(mapAttributesResetFilters(dataType))
        break
      }
      return
  }
  const afterUpdateState = getState()
  // сохранение изменений настроек фильтрации
  if (prevState !== afterUpdateState) {
    dispatch(controlFilterSettings(dataType))
  }
}

export const resetFiltersInAllTables = () => async (dispatch) => {
  await dispatch(resetFilterInTable(DATA_TYPES.SITES))
  await dispatch(resetFilterInTable(DATA_TYPES.BASE_STATIONS))
  await dispatch(resetFilterInTable(DATA_TYPES.SECTORS))
  await dispatch(resetFilterInTable(DATA_TYPES.COMPLAINTS))
}
