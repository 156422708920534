import { NETWORK_ELEMENT_COUNT } from '../constants/network'
import { splitToBlocks } from '../utils/bundle'
import { polygonToGeoJSON, geoJSONToPolygon } from '../utils/convert'
import { fetch } from './rest'

export const getConfig = () => fetch('/api/config') // , 'GET', undefined, true, true, window.location.origin

export const getComplaints = () => fetch('/api/complaints')

export const getComplaintsForPeriod = (data) => fetch('/api/history/complaints', 'POST', data)

export const getSites = (activeProjectId = '_') => fetch(`/api/project/${activeProjectId}/network/sites`)

export const getBaseStations = (activeProjectId = '_') => fetch(`/api/project/${activeProjectId}/network/rbses`)

export const getSectors = (activeProjectId = '_') => fetch(`/api/project/${activeProjectId}/network/sectors`)

export const BUNDLE_TREE_LEVELS = [ 'sites', 'rbses', 'sectors' ]

const setChildBundle = (bundle, level, child) => {
  const result = prepareBundle(child, level + 1)
  if (Object.keys(result).length) {
    const key = BUNDLE_TREE_LEVELS[level]
    if (!bundle[key]) {
      bundle[key] = {}
    }
    bundle[key][child.id] = result
  }
}

const setAttribute = (bundle, id, value) => {
  if (!bundle.attributes) {
    bundle.attributes = {}
  }
  bundle.attributes[id] = value
}

const setOrigin = (bundle, id, value) => {
  if (!bundle.origins) {
    bundle.origins = {}
  }
  bundle.origins[id] = value
}

export const prepareBundle = (item, level = 0) => {
  const bundle = {}
  if (item.modified) {
    for (let i = 0; i < item.fields.length; i++) {
      if (item.modified[i]) {
        setAttribute(bundle, item.fields[i].id, item.element[i])
        if (item.origin) {
          setOrigin(bundle, item.fields[i].id, item.origin[i])
        }
      }
    }
  }
  if (item.children) {
    for (const child of item.children) {
      setChildBundle(bundle, level, child)
    }
  }
  return bundle
}

export const postBundle = (activeProjectId, bundle) => {
  // console.log(activeProjectId, 'PATCH', bundle)
  return fetch(`/api/project/${activeProjectId}/network`, 'PATCH', bundle)
}

export const updateBundle = async (data) => {
  // console.log('updateBundle', data)
  const {
    activeProjectId = '_', saveToCache, shouldSplitToBlocks,
    bundle, changesCount, startSaving, updateSaving, finishSaving,
  } = data
  // console.log('bundle', bundle)
  if (Object.keys(bundle)) {
    if (saveToCache) {
      saveToCache(bundle)
    } else {
      if (shouldSplitToBlocks) {
        if (startSaving) {
          startSaving(changesCount)
        }

        let counter = 0
        let successful = 0
        let failed = 0
        const blocks = splitToBlocks(bundle, NETWORK_ELEMENT_COUNT)
        for (const block of blocks) {
          const { bundle, size } = block
          try {
            await postBundle(activeProjectId, bundle)
            successful += size
          } catch (e) {
            failed += size
          }
          if (updateSaving) {
            counter += size
            updateSaving(changesCount, counter)
          }
        }

        if (finishSaving) {
          finishSaving()
        }

        return {
          ...data,
          successful,
          failed,
        }
      } else {
        await postBundle(activeProjectId, bundle)
      }
    }
  }
  return data
}

export const updateBundlePrepare = async (data) => {
  const { activeProjectId = '_', bundle, saveToCache } = data
  if (Object.keys(bundle).length) {
    if (saveToCache) {
      await saveToCache(bundle)
    } else {
      await postBundle(activeProjectId, bundle)
    }
  }
  return data
}

export const getSiteTargetCoverage = ({ siteId, activeProjectId = '_' }) =>
  fetch(`/api/project/${activeProjectId}/target/site/${siteId}`)
    .then((result) => {
      for (const key of Object.keys(result)) {
        const [ [ geometry, properties ] ] = geoJSONToPolygon(result[key])
        result[key] = geometry
        result[`${key}_properties`] = properties
      }
      return result
    })

export const getSitesTargetCoverage = ({ siteIds, activeProjectId = '_' }) =>
  fetch(`/api/project/${activeProjectId}/target/sites`, 'POST', { siteIds })
    .then((result) => {
      for (const key of Object.keys(result)) {
        const [ [ geometry, properties ] ] = geoJSONToPolygon(result[key])
        result[key] = geometry
        result[`${key}_properties`] = properties
      }
      return result
    })

export const updateSectorTargetCoverage = ({ siteId, sectorId, activeProjectId = '_', polygon, properties }) =>
  fetch(`/api/project/${activeProjectId}/target/site/${siteId}/sector/${sectorId}`, 'PATCH',
    polygonToGeoJSON([ {
      id: sectorId,
      coordinates: polygon,
      properties,
    } ]))

export const fillSectorsCoverages = ({ activeProjectId = '_', sectors }) =>
  fetch(`/api/project/${activeProjectId}/target/copy-by-sectors`, 'POST', sectors)

export const deleteAllCoverages = ({ activeProjectId = '_' }) =>
  fetch(`/api/project/${activeProjectId}/target`, 'DELETE')

export const getCoverage = (activeProjectId = '_') =>
  fetch(`/api/project/${activeProjectId}/geomaps?types=RASTER_ATOLL`, 'GET')

export const getNextSiteName = (activeProjectId = '_') =>
  fetch(`/api/project/${activeProjectId}/network/sites/nextName`)

export const getNextSectorName = ({ activeProjectId = '_', siteName }) =>
  fetch(`/api/project/${activeProjectId}/network/sectors/nextName`, 'POST', { siteName })

export const requestSites = ({ projectId = '_', sites }) =>
  fetch(`/api/project/${projectId}/network/sites`, 'POST', sites)

export const requestRBSes = ({ projectId = '_', rbses }) =>
  fetch(`/api/project/${projectId}/network/rbses`, 'POST', rbses)

export const requestSectors = ({ projectId = '_', sectors }) =>
  fetch(`/api/project/${projectId}/network/sectors`, 'POST', sectors)

export const requestSitesBySiteNames = ({ projectId = '_', siteNames }) =>
  fetch(`/api/project/${projectId}/network/sites/by-sites`, 'POST', siteNames)

export const requestRBSesBySiteNames = ({ projectId = '_', siteNames }) =>
  fetch(`/api/project/${projectId}/network/rbses/by-sites`, 'POST', siteNames)

export const requestSectorsBySiteNames = ({ projectId = '_', siteNames }) =>
  fetch(`/api/project/${projectId}/network/sectors/by-sites`, 'POST', siteNames)

export const getNetworkTemplates = () =>
  fetch('/conf/network_templates.json', 'GET', undefined, 'json', true, window.location.origin)
