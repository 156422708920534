import React, { useCallback, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useBoolean } from '@fluentui/react-hooks'
import SelectOperation from '../common/SelectOperation'
import { selectUserAccess } from '../../features/login/loginSlice'
import {
  getAllBusinessCases, getFinancialIndicators, getLaunchedSitesAndSectors, getNeighbors,
  requestCoverageByPointListReport, /* requestCoverageByZoneListReport, */ requestNeighborsReport,
  setResolve,
} from '../../features/reports/reportsSlice'
// import { selectVisibleFocusZoneCount } from '../../features/geo/geoSlice'
import { useConfirm } from '../common/Confirm'
import { readFileContentXLSX, tableToExcel } from '../../utils/export'
import { alwaysTrue } from '../../utils/format'
// import { APPROVE_BUSINESS_CASES } from '../../constants/access'
import SelectSites from './SelectSites'
import SelectFinancials from './SelectFinancials'
import SelectPeriod from './SelectPeriod'

const OPERATIONS = [
  {
    title: 'All Business Cases',
    // eslint-disable-next-line max-len
    description: 'This report contains information about all available business cases in the SmartCapex system (except PostLaunch)',
    icon: 'ExcelDocument',
    // access: APPROVE_BUSINESS_CASES,
  },
  {
    title: 'Financial Indicators',
    description: 'This report contains calculation of financial indicators for Sites and total for group business' +
      ' case.',
    icon: 'ExcelDocument',
  },
  {
    title: 'Launched Sites and Sectors',
    description: 'This report contains list of Sites and Sectors that were launched according to the agreed business' +
      ' cases in the SmartCapex system (within a year from the date of launch).',
    icon: 'ExcelDocument',
  },
  {
    title: 'Neighbors',
    description: 'This report contains list of Neighbors (currently available) after building the network element' +
      ' (within a year from the date of launch).',
    icon: 'ExcelDocument',
  },
  {
    title: 'Third Party Queries',
    description: 'Open section with reports for Third Party Queries.',
    icon: 'OpenFolderHorizontal',
  },
]

const thirdPartyOperations = [
  {
    title: 'Template for Point List',
    description: 'Download template for Point List reports.',
    icon: 'OpenEnrollment',
  },
  {
    title: 'Network Service for Point List',
    description: 'This report returns servicing Network elements for the list of geographical points' +
      ' supplied by Excel file.',
    icon: 'ExcelDocument',
  },
  /* {
    title: 'Network Service for Zone List',
    description: 'This report returns servicing Network elements for the list of Focus zones.',
    icon: 'ExcelDocument',
  }, */
]

const HEADER_NUMBER = '№'
const HEADER_ADDRESS = 'Address'
const HEADER_LATITUDE = 'Latitude'
const HEADER_LONGITUDE = 'Longitude'

const templateHeaders = [
  HEADER_NUMBER,
  HEADER_ADDRESS,
  HEADER_LATITUDE,
  HEADER_LONGITUDE,
]

const xlsxFileInputId = 'xlsx-file-input-reports'

const clearValue = (event) => {
  event.target.value = null
}

const SelectOperationReports = ({ onClose }) => {
  const dispatch = useDispatch()
  const { renderConfirm, msg } = useConfirm()

  const userAccess = useSelector(selectUserAccess, shallowEqual)
  // const focusZoneCount = useSelector(selectVisibleFocusZoneCount)

  const [ showSelectSites, setShowSelectSites ] = useState(false)
  const [ showSelectFinancials, setShowSelectFinancials ] = useState(false)
  const [ showThirdPartyQueries, setShowThirdPartyQueries ] = useState(false)

  const [ isShowSelectPeriod, { setTrue: showSelectPeriod, setFalse: hideSelectPeriod } ] = useBoolean(false)
  const [ selectedPeriod, setSelectedPeriod ] = useState(null)

  const allBusinessCases = useCallback(() => {
    onClose()
    dispatch(getAllBusinessCases)
  }, [ onClose, dispatch ])

  const doFinincialIndicators = useCallback((businessCaseGroup) => {
    onClose()
    dispatch(getFinancialIndicators(businessCaseGroup))
  }, [ onClose, dispatch ])

  const onCancelFinancials = useCallback(() => {
    setShowSelectFinancials(false)
  }, [ setShowSelectFinancials ])

  const financialIndicators = useCallback(() => {
    setShowSelectFinancials(true)
  }, [ setShowSelectFinancials ])

  const launchedSitesAndSectors = useCallback(() => {
    onClose()
    dispatch(getLaunchedSitesAndSectors)
  }, [ onClose, dispatch ])

  const neighbors = useCallback(async () => {
    const siteListLength = await dispatch(getNeighbors)
    if (!siteListLength) {
      return msg({ messages: [ 'There are no Sites matching the criteria' ] }, onClose)
    }
    try {
      await new Promise((resolve, reject) => {
        dispatch(setResolve({ resolve, reject }))
        setShowSelectSites(true)
      })
      await dispatch(requestNeighborsReport)
      msg(
        { messages: [ 'Neighbors report is being prepared.', 'You will receive a notification when it is ready.' ] },
        onClose,
      )
    } catch (err) {
      onClose()
    }
  }, [ onClose, dispatch, msg, setShowSelectSites ])

  const thirdPartyQueries = useCallback(() => {
    setShowThirdPartyQueries(true)
  }, [ setShowThirdPartyQueries ])

  const actions = useMemo(() => [
    allBusinessCases,
    financialIndicators,
    launchedSitesAndSectors,
    neighbors,
    thirdPartyQueries,
  ].filter((_, index) => !OPERATIONS[index].access || userAccess[OPERATIONS[index].access]),
  [ allBusinessCases, financialIndicators, launchedSitesAndSectors, neighbors, userAccess, thirdPartyQueries ])

  const operations = useMemo(() => OPERATIONS.filter(({ access }) => !access || userAccess[access]), [ userAccess ])

  const onCloseThirdPartyQueries = useCallback(() => {
    setShowThirdPartyQueries(false)
  }, [ setShowThirdPartyQueries ])

  const thirdPartyQueryTemplate = useCallback(() => {
    tableToExcel(templateHeaders, [], alwaysTrue, 'Points', 'Template for Point List.xlsx')
    onCloseThirdPartyQueries()
    onClose()
  }, [ onClose, onCloseThirdPartyQueries ])

  const thirdPartyQueryByPoints = useCallback(() => {
    document.getElementById(xlsxFileInputId).click()
  }, [])

  const doThirdPartyQueryByPoints = useCallback((period) => {
    setSelectedPeriod(period)
    hideSelectPeriod()
    setTimeout(() => {
      thirdPartyQueryByPoints()
    }, 1)
  }, [ hideSelectPeriod, thirdPartyQueryByPoints ])

  /* const thirdPartyQueryByZones = useCallback(async () => {
    if (!focusZoneCount) {
      return msg({ messages: [ 'There are no checked Focus Zones on the map' ] })
    }
    await dispatch(requestCoverageByZoneListReport)
    onCloseThirdPartyQueries()
    onClose()
  }, [ onCloseThirdPartyQueries, onClose, dispatch, focusZoneCount, msg ]) */

  const doShowSelectPeriod = useCallback(() => {
    setSelectedPeriod(null)
    showSelectPeriod()
  }, [ showSelectPeriod ])

  const thirdPartyActions = useMemo(() => [
    thirdPartyQueryTemplate,
    doShowSelectPeriod,
    // thirdPartyQueryByZones,
  ], [ /* thirdPartyQueryByZones, */ thirdPartyQueryTemplate, doShowSelectPeriod ])

  const xlsxFileChange = useCallback(async () => {
    const input = document.getElementById(xlsxFileInputId)
    const [ headerNames, ...cells ] = await readFileContentXLSX(input.files[0])
    const idxNumber = headerNames.indexOf(HEADER_NUMBER)
    const idxAddress = headerNames.indexOf(HEADER_ADDRESS)
    const idxLatitude = headerNames.indexOf(HEADER_LATITUDE)
    const idxLongitude = headerNames.indexOf(HEADER_LONGITUDE)
    if (idxNumber === -1 || idxAddress === -1 || idxLatitude === -1 || idxLongitude === -1) {
      return msg({ messages: [ 'Invalid file format' ] })
    }
    const pointList = cells
      .map((row) => {
        const number = parseInt(row[idxNumber]?.trim()) || null
        const address = row[idxAddress]?.trim() || null
        const latitude = parseFloat(row[idxLatitude]?.trim()) || null
        const longitude = parseFloat(row[idxLongitude]?.trim()) || null
        if (!number || (!address && (!latitude || !longitude))) {
          return null
        }
        return ({ number, address, latitude, longitude })
      })
      .filter(Boolean)
    await dispatch(requestCoverageByPointListReport(pointList, selectedPeriod))
    onCloseThirdPartyQueries()
    onClose()
  }, [ onClose, onCloseThirdPartyQueries, dispatch, msg, selectedPeriod ])

  return (
    <>
      <SelectOperation
        caption="Reports"
        operations={operations}
        actions={actions}
        onClose={onClose}
      />
      {showThirdPartyQueries && (
        <SelectOperation
          caption="Reports for Third Party Queries"
          operations={thirdPartyOperations}
          actions={thirdPartyActions}
          onClose={onCloseThirdPartyQueries}
        />
      )}
      {renderConfirm()}
      {showSelectSites && (
        <SelectSites />
      )}
      {showSelectFinancials && (
        <SelectFinancials onCancel={onCancelFinancials} onSubmit={doFinincialIndicators} />
      )}
      {isShowSelectPeriod && (
        <SelectPeriod onCancel={hideSelectPeriod} onSubmit={doThirdPartyQueryByPoints} />
      )}
      <input
        id={xlsxFileInputId}
        type="file"
        accept=".xlsx"
        style={{ display: 'none' }}
        onChange={xlsxFileChange}
        onClick={clearValue}
      />
    </>
  )
}

export default SelectOperationReports
