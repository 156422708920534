import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Toaster } from 'react-hot-toast'
import { useConst } from '@fluentui/react-hooks'
import { Stack } from '@fluentui/react'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { loadSettings } from '../features/settings/settingsSlice'
import { selectUser } from '../features/login/loginSlice'
import {
  activeProjectId,
  selectIsActiveProjectRemoved,
  selectIsActiveProjectUnshared,
  selectIsActiveProjectOwnerChanged,
} from '../features/projects/projectsSlice'
import Cabinet from '../components/Cabinet'
import { initCellTypes } from '../components/common/utils/hotTable'
import Drawer from '../components/Drawer'
import { LocationLog, ErrorHandling } from '../components/EventLog/LocationLog'
import { useConfirm } from '../components/common/Confirm'
import info from '../../package.json'
import { ORIGIN } from '../api/rest'
import Header from './Header'
import Footer from './Footer'
import Project from './Project'
import Panel from './Panel'
import HeaderContext from './context/HeaderContext'

import './Main.css'

initCellTypes()

const APP_VERSION_CHECK_MIN = 5 // 5 minutes

const reloadWithoutCache = () => {
  const url = new URL(window.location.href)
  url.searchParams.set('nocache', new Date().getTime())
  window.location.href = url.toString()
}

const getAppVersion = () => {
  return window.fetch(`${ORIGIN}/version.txt`, { cache: 'no-store' })
}

const isUpper = (appVersion, currentVersion) => {
  const appVersionParts = appVersion.split('.')
  const currentVersionParts = currentVersion.split('.')

  for (let i = 0; i < appVersionParts.length; i++) {
    const appInt = parseInt(appVersionParts[i]) || 0
    const currentInt = parseInt(currentVersionParts[i]) || 0
    if (appInt > currentInt) {
      return true
    }
  }

  return false
}

const Main = ({ routerProps }) => {
  const dispatch = useDispatch()

  const user = useSelector(selectUser)
  const projectId = useSelector(activeProjectId)
  const isActiveProjectRemoved = useSelector(selectIsActiveProjectRemoved)
  const isActiveProjectUnshared = useSelector(selectIsActiveProjectUnshared)
  const isActiveProjectOwnerChanged = useSelector(selectIsActiveProjectOwnerChanged)

  const [ importMenu, setImportMenu ] = useState([])
  const [ exportMenu, setExportMenu ] = useState([])
  const [ filterMenu, setFilterMenu ] = useState([])
  const [ taskLogActions, setTaskLogActions ] = useState({})
  const [ trfActions, setTrfActions ] = useState({})
  const [ macroIndicatorActions, setMacroIndicatorActions ] = useState({})
  const [ businessCasesActions, setBusinessCasesActions ] = useState({})
  const [ createBusinessCaseActions, setCreateBusinessCaseActions ] = useState({})
  const [ notificationsActions, setNotificationsActions ] = useState({})
  const [ networkItemsActions, setNetworkItemsActions ] = useState({})
  const [ coverageMenu, setCoverageMenu ] = useState([])

  const headerContext = useMemo(() => ({
    importMenu,
    setImportMenu,
    exportMenu,
    setExportMenu,
    filterMenu,
    setFilterMenu,
    taskLogActions,
    setTaskLogActions,
    trfActions,
    setTrfActions,
    macroIndicatorActions,
    setMacroIndicatorActions,
    businessCasesActions,
    setBusinessCasesActions,
    createBusinessCaseActions,
    setCreateBusinessCaseActions,
    notificationsActions,
    setNotificationsActions,
    networkItemsActions,
    setNetworkItemsActions,
    coverageMenu,
    setCoverageMenu,
  }), [
    importMenu, setImportMenu, exportMenu, setExportMenu, filterMenu, setFilterMenu, taskLogActions, setTaskLogActions,
    trfActions, setTrfActions, macroIndicatorActions, setMacroIndicatorActions,
    businessCasesActions, setBusinessCasesActions, createBusinessCaseActions, setCreateBusinessCaseActions,
    setNotificationsActions, notificationsActions, networkItemsActions, setNetworkItemsActions,
    coverageMenu, setCoverageMenu,
  ])

  const { renderConfirm, msg } = useConfirm()
  const currentVersion = useMemo(() => `${info.version}.${info.buildNumber}`, [])
  const [ appVersion, setAppVersion ] = useState(null)

  useEffect(() => {
    const doLoadAppVersion = async () => {
      try {
        const result = await getAppVersion()
        if (result) {
          const version = (await result.text())?.trim()
          const match = version.match(/(\d+\.\d+\.\d+)(?:\.\d+)?/)
          if (match) {
            setAppVersion(version)
            return
          } else {
            console.warn('App version is not in the correct format')
          }
        }
      } catch (e) {
        console.warn('App version can\'t be retrieved')
        console.error(e)
      }
      setAppVersion(null)
    }

    doLoadAppVersion()
    const checkVersionInterval = setInterval(doLoadAppVersion, APP_VERSION_CHECK_MIN * 60 * 1000)

    return () => {
      clearInterval(checkVersionInterval)
    }
  }, [])

  useEffect(() => {
    if (appVersion !== null && isUpper(appVersion, currentVersion)) {
      msg(
        {
          title: 'Code Outdated',
          subTitle: 'Page Refresh Required',
          messages: [
            // eslint-disable-next-line max-len
            `We have detected that the version of this page is outdated (${currentVersion} ➟ ${appVersion}). In order to continue using this application and ensure you have the latest updates and features, please refresh your browser page.`,
            // eslint-disable-next-line max-len
            'Refreshing the page will reload the latest code and data, providing you with the best and most secure experience possible.',
          ],
          textYesBtn: 'Refresh Page',
        },
        () => {
          reloadWithoutCache()
        },
      )
    }
  }, [ msg, currentVersion, appVersion ])

  useEffect(() => {
    if (isActiveProjectOwnerChanged) {
      msg(
        {
          title: 'Project owner changed',
          subTitle: 'Page Refresh Required',
          messages: [
            // eslint-disable-next-line max-len
            'The project owner has been changed. Please refresh your browser page.',
          ],
          textYesBtn: 'Refresh Page',
        },
        () => {
          reloadWithoutCache()
        },
      )
    }
  }, [ msg, isActiveProjectOwnerChanged ])

  useEffect(() => {
    dispatch(loadSettings(projectId))
  }, [ projectId, dispatch ])

  useEffect(() => {
    if (isActiveProjectRemoved || isActiveProjectUnshared) {
      msg(
        {
          title: `Project ${isActiveProjectUnshared ? 'Unshared' : 'Removed'}`,
          messages: [
            // eslint-disable-next-line max-len
            `The active project has been ${isActiveProjectUnshared ? 'unshared' : 'removed'}. You will be redirected to the default project.`,
          ],
          textYesBtn: 'Redirect to Default',
          allowDismiss: false,
        },
        () => {
          window.location.replace('/_/map')
        },
      )
    }
  }, [ isActiveProjectRemoved, isActiveProjectUnshared, msg ])

  const path = routerProps.location.pathname
  const paths = path.split('/')
  const parentPath = paths[1] ?? ''
  const lastProjectId = useConst(() => localStorage.getItem(`last_project_id_${user?.id || ''}`) || '_')

  return (
    <Stack horizontal>
      <Drawer routes={routerProps} />
      <Stack className="layout-center-column">
        <HeaderContext.Provider value={headerContext}>
          <Header routes={routerProps} />
          <Switch>
            <Route path='/cabinet/:tab'>
              <Cabinet />
            </Route>
            <Route path='/:projectId/:tab/:vectorMapId'>
              <Project />
            </Route>
            <Route path='/:projectId/:tab'>
              <Project />
            </Route>
            <Redirect from={'/cabinet'} to={`/${parentPath}/projects`} />
            <Redirect from={'/:projectId'} to={path.slice(-1) === '/' ? `${path}map` : `${path}/map`} />
            <Redirect from='/' to={`/${lastProjectId}`} />
          </Switch>
        </HeaderContext.Provider>
        <Footer />
        <LocationLog />
        <ErrorHandling />
        <Toaster />
        {renderConfirm()}
      </Stack>
      <Panel />
    </Stack>
  )
}

export default withRouter((routerProps) => <Main routerProps={routerProps}/>)
